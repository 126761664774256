////
/// @group Modules
/// Illustration
////

.m-illu {
  position: absolute;
  right: 0;
  bottom: 0;
  user-select: none;
}
