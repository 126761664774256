////
/// @group Components
/// Position
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-position {
  position: relative;
  padding: 20px;
  color: cv("black");
  text-decoration: none;
  background: #fef5f6;
  border: 5px solid cv("white");
  border-radius: 11px;

  &.is-unavailable {
    cursor: not-allowed;
    opacity: 0.75;
  }

  &__figure {
    position: relative;
    width: 155px;
    height: 155px;

    img {
      width: 100%;
      height: 100%;
      border-radius: graphics.$ui-border-radius;
      object-fit: cover;
    }

    .c-tag {
      position: absolute;
      top: 0;
      right: 0;
    }

    .c-priority {
      position: absolute;
      top: 40px;
      right: 0;
      transform: translateX(50%);
    }
  }

  &__header,
  &__main {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }

  &__header {
    margin-bottom: 10px;
  }

  &__id {
    color: cv("gray", 900);
    font-size: 10px;
  }

  &__format {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
  }

  &__zone {
    margin: 0;
    font-size: 10px;
  }

  &__check {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 20px;
    height: 20px;
    background-color: cv("white");
    border: 0;
    border-radius: 50%;
    box-shadow: 0 0 0 2px rgba(cv("primary"), 0.7);
    cursor: pointer;
    transition: box-shadow 0.35s graphics.$ui-transition-timing-function;
    appearance: none;

    &:checked,
    &.is-checked {
      background-image: url(iv(
        success,
        $options: ("currentColor": cv("primary"))
      ));
      background-repeat: no-repeat;
      background-position: center;
      background-size: 105%; // Avoid aliasing
    }

    &:disabled {
      background-color: cv("gray", D00);
      box-shadow: 0 0 0 2px rgba(cv("gray", A00), 0.7);
      cursor: not-allowed;
    }

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      outline: 0;
      box-shadow: 0 0 0 5px rgba(cv("primary"), 0.7);
    }
  }
}

.c-positions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 16px;
  margin-bottom: 50px;
}
