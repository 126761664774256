////
/// @group Layout
/// Form
////

@use "@glsass/tools/tools.global" as *;

.l-form {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &-header {
    color: cv("white");
    background-color: cv("black");

    span,
    p {
      display: block;
      margin-bottom: 5px;
      color: cv("gray", "900");
      font-size: 10px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }

    h1 {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 700;

      &::before {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-position: top left;
        background-size: contain;
        filter: invert(1);
      }

      &[class^="is-"] {
        &::before {
          content: "";
        }
      }

      &.is-new {
        &::before {
          background-image: url(iv("add"));
        }
      }

      &.is-edit {
        &::before {
          background-image: url(iv("edit"));
        }
      }

      &.is-import {
        &::before {
          background-image: url(iv("import"));
        }
      }

      &.is-confirm {
        &::before {
          background-image: url(iv("confirm"));
        }
      }

      &.is-kit {
        &::before {
          background-image: url(iv("kit"));
        }
      }
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 40px 0 70px;
    background-color: cv("gray", 300);

    &--alt {
      padding: 0 0 40px;
      background-color: transparent;
    }
  }

  &-compo {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 70px;
  }

  &-nav {
    position: sticky;
    top: 0;
    z-index: 400;
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: 1px;
    padding: 10px 0;
    font-size: 12px;
    background: cv("black");

    a {
      cursor: pointer;

      &.is-active {
        font-weight: 700;
      }
    }
  }

  &-section {
    scroll-margin: 30px;
  }
}
