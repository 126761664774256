////
/// @group Components
/// Campaign card
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-campaign-card {
  --img-offset: 35px;

  padding-top: var(--img-offset);

  &:not(:last-child) {
    margin: 0 0 50px 0;
  }

  &__main {
    padding: 0 60px 20px 60px;
    color: cv("black");
    background-color: cv("white");
    box-shadow: 0 3px 6px rgba(cv("black"), 0.15);
  }

  &__img {
    display: block;
    width: 220px;
    height: 220px;
    margin-top: calc(var(--img-offset) * -1);
    border-radius: graphics.$ui-border-radius;
  }

  &__cols-wrapper {
    padding: 20px 0;
    border-top: 1px solid cv("gray", D00);
    border-bottom: 1px solid cv("gray", D00);
  }

  &__cols {
    display: flex;
    margin: 0 -30px;
  }

  &__col {
    flex: 1 0 0px;
    padding: 0 30px;

    &:not(:last-child) {
      border-right: 1px solid cv("gray", D00);
    }

    &__title {
      margin-bottom: 10px;
      color: #707070;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  &__summary {
    display: flex;
    padding: 20px 30px;
    color: cv("gray", B00);
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    background-color: cv("black");
    border-radius: 0 0 20px 20px;
    box-shadow: 0 3px 6px rgba(cv("black"), 0.15);

    & > div {
      flex: 0 0 25%;
      padding: 0 30px;

      strong {
        margin-right: 5px;
        color: cv("white");
        font-size: 38px;
        font-weight: 900;
      }

      &:not(:last-child) {
        border-right: 1px solid cv("gray", 700);
      }
    }
  }
}
