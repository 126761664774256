////
/// @group Components
/// Status indicator
////

@use "@glsass/tools/tools.global" as *;

.c-status {
  display: inline-block;
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  background-color: cv("gray", B00);
  border-radius: 50%;

  &--success {
    background-color: cv(success);
  }

  &--warning {
    background-color: cv(warning);
  }

  &--danger {
    background-color: cv(danger);
  }
}
