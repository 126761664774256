////
/// @group Components
/// Nav
////

@use "sass:math";

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-navbar {
  display: flex;
  justify-content: center;
  height: 70px;

  &__list {
    display: flex;
    margin-bottom: 0;
    list-style: none;
  }

  &__item {
    position: relative;
    display: flex;

    &:not(:last-child)::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 30px;
      background-color: cv("gray", E00);
      transform: translateY(-50%);
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 30px;
    overflow: hidden;
    color: cv("gray", 400);
    text-decoration: none;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6px;
      background-color: cv("danger");
      background-image: linear-gradient(to right, cv("purple"), cv("danger"));
      transform: translateY(110%);
      transition: all 0.35s graphics.$ui-transition-timing-function;
    }

    &:hover,
    &:focus {
      &::after {
        transform: translateY(0) !important;
      }
    }

    &:focus {
      outline: 0;
    }

    &.is-active {
      font-weight: 700;

      &::after {
        transform: translateY(3px);
      }
    }
  }
}

.c-subnav {
  flex-shrink: 0;
  box-shadow: 0 3px 3px rgba(cv("black"), 0.2);

  &__list {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }

  &__link {
    display: block;
    padding: 10px 20px;
    color: cv("white");
    font-size: 12px;
    line-height: math.div(10, 12);
    text-decoration: none;
    cursor: default;

    &:is(a) {
      cursor: pointer;
    }

    &.is-active {
      font-weight: 700;
    }
  }
}
