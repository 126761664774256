////
/// @group Components
/// Spinner
////

@use "@glsass/tools/tools.global" as *;

.c-spinner {
  width: 40px;
  height: 40px;
  border: 5px solid rgba(cv("gray", 800), 0.5);
  border-top: 5px solid cv("primary");
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
