////
/// @group Components
/// Reference mini (variant
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-ref-mini {
  &__header {
    display: flex;
    height: 30px;
  }

  &__title {
    display: flex;
    flex-grow: 1;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    color: cv("white");
    font-weight: 700;
    background-color: cv("gray", 300);
  }

  &__indicator {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 1px;
    padding: 0 20px;
    color: cv("white");
    font-weight: 700;
    background-color: cv("black");

    span {
      margin-right: 10px;
      color: cv("gray", B00);
      font-size: 10px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  &__body {
    position: relative;
    margin-bottom: 1px;
    padding: 10px;
    background-color: cv("white");

    .c-tag {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }

  &__image {
    position: relative;
    display: block;
    width: 80px;
    height: 80px;
    margin-right: 20px;
    overflow: hidden;
    border-radius: graphics.$ui-border-radius;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: cv("gray", B00);
      border: 1px dashed cv("danger");
      border-radius: graphics.$ui-border-radius;
    }

    img {
      position: relative;
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      background-color: cv("primary");
      background-image: url(iv(
        "edit",
        $options: ("currentColor": cv("white"))
      ));
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px;
      border: 2px solid cv("white");
      border-radius: 50%;
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: all 0.35s graphics.$ui-transition-timing-function;
    }

    &:where(a):hover {
      &::after {
        opacity: 1;
      }
    }
  }
}
