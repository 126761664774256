////
/// @group Components
/// Button
////

@use "sass:math";

@use "@glsass/graphics";

@use "@glsass/tools/tools.color" as _color;
@use "@glsass/tools/tools.string" as _str;
@use "@glsass/tools/tools.global" as *;

.c-button {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  height: 40px;
  padding: 0 30px;
  color: cv("white");
  font-weight: 900;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  border: 0;
  border-radius: graphics.$ui-border-radius;
  transition: all 0.35s graphics.$ui-transition-timing-function;
  user-select: none;

  &:not(:disabled):not(.is-disabled):hover {
    color: cv("primary");
    text-decoration: none;
    cursor: pointer;
  }

  &:focus,
  &.is-focus {
    color: cv("primary");
    outline: 0;
    box-shadow: 0 0 0 math.div(1rem, 4) rgba(cv(primary), 0.25);
  }

  &:disabled,
  &.is-disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &.is-next {
    &::after {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-left: 10px;
      background-image: url(iv(
        "arrow",
        $options: ("currentColor": cv("white"))
      ));
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  &.is-prev {
    &::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 10px;
      background-image: url(iv(
        "arrow",
        $options: ("currentColor": cv("white"))
      ));
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transform: rotate(0.5turn);
      transition: all 0.35s graphics.$ui-transition-timing-function;
    }

    &:hover,
    &:focus {
      &::before {
        background-image: url(iv(
          "arrow",
          $options: ("currentColor": cv("primary"))
        ));
      }
    }
  }

  &.is-loading {
    &::after {
      background-image: url(iv(
        "loading",
        $options: ("currentColor": cv("white"))
      ));
      animation-name: loading;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }

    &:disabled,
    &.is-disabled {
      opacity: 1;
    }
  }

  &[class*="has-"] {
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  &.has-add,
  &.has-remove,
  &.has-target,
  &.has-refresh,
  &.has-delivery {
    &::before {
      background-color: cv(primary);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 10px;
      border-radius: 50%;
    }
  }

  &.has-add {
    &::before {
      background-image: url(iv("add", $options: ("currentColor": cv("white"))));
    }
  }

  &.has-remove {
    &::before {
      background-image: url(iv(
        "delete",
        $options: ("currentColor": cv("white"))
      ));
    }
  }

  &.has-target {
    &::before {
      background-image: url(iv(
        "target",
        $options: ("currentColor": cv("white"))
      ));
      background-size: 11px;
    }
  }

  &.has-refresh {
    &::before {
      background-image: url(iv(
        "refresh",
        $options: ("currentColor": cv("white"))
      ));
    }
  }

  &.has-delivery {
    &::before {
      background-image: url(iv(
        "delivery",
        $options: ("currentColor": cv("white"))
      ));
    }
  }

  &.has-delete {
    &::before {
      background-color: currentColor;
      mask-image: url(iv(delete));
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
    }
  }

  &.has-download {
    &::before {
      background-color: currentColor;
      mask-image: url(iv(download-alt));
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
    }
  }

  &--text {
    color: cv("text");
  }

  &--black {
    color: cv("black");
  }

  &--primary {
    color: cv("white");
    background-color: cv("purple");
    background-image: linear-gradient(to right, transparent, cv("danger"));

    &:not(:disabled):not(.is-disabled):hover {
      color: cv("white");
      background-color: cv("danger");
    }

    &:focus {
      color: cv("white");
      background-color: cv("danger");
      box-shadow: 0 0 0 math.div(1rem, 4) rgba(cv("danger"), 0.25);
    }
  }

  &--secondary {
    color: cv("white");
    background-color: cv("secondary");
    background-image: linear-gradient(to right, transparent, cv("purple"));

    &:not(:disabled):not(.is-disabled):hover {
      color: cv("white");
      background-color: cv("purple");
    }

    &:focus {
      color: cv("white");
      background-color: cv("purple");
      box-shadow: 0 0 0 math.div(1rem, 4) rgba(cv("purple"), 0.25);
    }
  }

  &--default {
    color: cv("white");
    background-color: cv("primary");

    &:not(:disabled):not(.is-disabled):hover {
      color: cv("white");
      background-color: _color.shade(cv("primary"), 2);
    }

    &:focus {
      color: cv("white");
      background-color: _color.shade(cv("primary"), 2);
      box-shadow: 0 0 0 0.25rem rgba(cv(primary), 0.25);
    }
  }

  &--white {
    color: cv("gray", 300);
    background-color: cv("white");

    &.is-next {
      &::after {
        background-image: url(iv(
          "arrow",
          $options: ("currentColor": cv("danger"))
        ));
      }
    }

    &.is-loading {
      &::after {
        background-image: url(iv(
          "loading",
          $options: ("currentColor": cv("danger"))
        ));
      }
    }
  }

  &--white-danger {
    color: cv("danger");
    background-color: cv("white");

    &.is-next {
      &::after {
        background-image: url(iv(
          "arrow",
          $options: ("currentColor": cv("danger"))
        ));
      }
    }

    &.is-loading {
      &::after {
        background-image: url(iv(
          "loading",
          $options: ("currentColor": cv("danger"))
        ));
      }
    }
  }

  &--outline {
    color: cv("white");
    background: rgba(cv("white"), 0.2);
    border: 1px solid cv("white");

    &:not(:disabled):not(.is-disabled):hover {
      color: cv("white");
      background-color: transparent;
    }

    &:focus {
      color: cv("white");
      background-color: transparent;
      box-shadow: 0 0 0 math.div(1rem, 4) rgba(cv("purple"), 0.25);
    }
  }

  &--cancel {
    color: cv("gray", B00);
    background-color: cv("gray", 700);

    &:not(:disabled):not(.is-disabled):hover {
      color: cv("white");
      background-color: cv("gray", B00);
    }

    &:focus {
      color: cv("white");
      background-color: cv("gray", B00);
      box-shadow: 0 0 0 math.div(1rem, 4) rgba(cv("gray", B00), 0.25);
    }

    &:disabled,
    &.is-disabled {
      opacity: 0.3;
    }
  }

  &--cancel-danger {
    color: cv("white");
    background-color: _color.tint(cv("danger"), 6);

    &:not(:disabled):not(.is-disabled):hover {
      color: cv("white");
      background-color: _color.tint(cv("danger"), 4);
    }

    &:focus {
      color: cv("white");
      background-color: _color.tint(cv("danger"), 4);
      box-shadow: 0 0 0 math.div(1rem, 4) rgba(cv("white"), 0.25);
    }
  }

  &--delete {
    color: cv(danger);
    background-color: cv("black");

    &::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 10px;
      background-color: cv(danger);
      mask-image: url(iv(delete));
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      transition: all 0.35s graphics.$ui-transition-timing-function;
    }

    &:not(:disabled):not(.is-disabled):hover {
      color: cv("white");
      background-color: cv("black");

      &::before {
        background-color: cv("white");
      }
    }

    &:focus {
      color: cv("white");
      background-color: cv("black");
      box-shadow: 0 0 0 math.div(1rem, 4) rgba(cv("white"), 0.25);

      &::before {
        background-color: cv("white");
      }
    }
  }

  &--square {
    justify-content: center;
    width: 40px;
    padding: 0;

    svg {
      width: 1rem;
      height: 1rem;
    }

    &.is-loading {
      animation-name: loading;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  &--circle {
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 50%;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }
}
