////
/// @group Components
/// Footer
////

@use "../settings" as core;

@use "@glsass/tools/tools.global" as *;

@use "@glsass/graphics";

.c-form-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 60px;
  background-color: cv("black");

  &__modal {
    right: 20px;
    bottom: 15px;
    left: 20px;
    width: auto;
  }

  &__modal-relative {
    position: relative;
    flex-shrink: 0;
    height: 60px;
    margin-top: auto;
  }
}
