////
/// @group Components
/// Button
////

@use "@glsass/tools/tools.global" as *;

.c-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: cv("white");
  background-color: cv("black");

  &__header {
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;

    &::before {
      content: "";
      display: inline-block;
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
      background-color: cv("gray", 400);
      mask-image: url(iv("warning-alt"));
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;

    > button,
    > .c-button {
      margin: 0 15px;
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(iv(close));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1rem 1rem;
  }

  &--light-bg,
  &--dark-bg {
    padding: 20px;

    &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100vw;
      height: 100vh;
    }
  }

  &--light-bg {
    &::before {
      background-color: rgba(cv("white"), 0.5) !important;
    }
  }

  &--dark-bg {
    &::before {
      background-color: rgba(cv("black"), 0.5) !important;
    }
  }

  &-add-targeting {
    .inner-content {
      height: calc(100% - 70px);
      overflow-y: auto;
    }
  }
}

// Overrides
.vm--modal {
  background-color: transparent !important;
}
