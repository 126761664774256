////
/// @group Components
/// Area mini
////

@use "../settings" as core;

@use "@glsass/tools/tools.global" as *;

.c-area-mini {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 14px 20px;
  background: cv("gray", "E00");
  cursor: pointer;
  opacity: 0.3;

  &.active {
    background: cv("white");
    opacity: 1;

    .c-area-mini__check {
      opacity: 1;
    }
  }

  &__txt {
    flex-grow: 1;
    width: 90%;
  }

  &__name {
    margin-bottom: 0;
    color: cv("black");
    font-size: 16px;
  }

  &__concepts {
    margin-bottom: 0;
    overflow: hidden;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__check {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    overflow: hidden;
    background: cv("primary");
    border-radius: 10px;
    opacity: 0;

    &-inner {
      width: 17px;
      height: 17px;
      margin-top: 1px;
      margin-left: 3px;
      background: cv("white");
      mask-image: url(iv(check));
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      content: "";
    }
  }
}
