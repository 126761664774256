////
/// @group Components
/// Lead
////

@use "@glsass/tools/tools.global" as *;

.c-lead {
  &s {
    display: flex;
    flex-direction: column;
    margin-bottom: -10px;
  }

  /* stylelint-disable-next-line order/order */
  position: relative;
  display: grid;
  grid-template-columns: 95px 1fr;
  margin-bottom: 10px;
  font-size: 12px;
  white-space: pre-line;

  &--small {
    grid-template-columns: 80px 1fr;
    margin-bottom: 7px;
    padding-right: 5px;
    font-size: 10px;
  }

  &--large {
    grid-template-columns: 140px 1fr;
  }

  &.is-valid,
  &.is-invalid {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 10px;
      height: 10px;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      transform: translateY(-50%);
    }
  }

  &.is-valid {
    &::after {
      background-color: cv(success);
      mask-image: url(iv(check));
    }
  }

  &.is-invalid {
    &::after {
      background-color: cv(danger);
      mask-image: url(iv(close));
    }
  }
}
