////
/// @group Components
/// Sidebar
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.vhd" as vhd;
@use "@glsass/tools/tools.global" as *;

.c-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 450px;
  height: 100%;
  margin-left: auto;
  background-color: cv("gray", 200);
  border-left: 1px solid cv("gray", 200);

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    padding-right: 20px;
    padding-left: 30px;
    color: cv("white");
    background-color: cv("black");
  }

  &__title {
    min-width: 0;

    span,
    p {
      display: block;
      margin-bottom: 5px;
      overflow: hidden;
      color: cv("gray", 700);
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    h1 {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 700;

      &::before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        background-image: url(iv("success"));
        background-repeat: no-repeat;
        background-position: top left;
        background-size: contain;
        filter: invert(1);
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: cv("gray", 200);
    border: 1px solid cv("gray", 300);
    transition: all 0.35s graphics.$ui-transition-timing-function;

    &:not(:disabled):not(.is-disabled):hover {
      z-index: 1;
      background-color: cv("gray", 300);
    }

    &:focus {
      z-index: 1;
      background-color: cv("gray", 300);
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(cv("white"), 0.25);
    }

    &:disabled,
    &.is-disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    span {
      @include vhd.visually-hidden();
    }

    &::after {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      background-image: url(iv(
        "chevron",
        $options: ("currentColor": cv("white"))
      ));
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &--prev {
      margin-right: 1px;
      border-top-left-radius: graphics.$ui-border-radius;
      border-bottom-left-radius: graphics.$ui-border-radius;

      &::after {
        transform: rotate(0.25turn);
      }
    }

    &--next {
      border-top-right-radius: graphics.$ui-border-radius;
      border-bottom-right-radius: graphics.$ui-border-radius;

      &::after {
        transform: rotate(-0.25turn);
      }
    }
  }

  &__main {
    height: 100%;
    padding: 30px;
    padding-bottom: 90px;
    overflow-y: auto;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 30px;
    background-color: cv("black");
  }

  &__form {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 100%;

    &-header {
      height: 40px;
      margin-bottom: 1px;
      padding: 0 30px;
      color: cv("white");
      font-weight: 900;
      line-height: 40px;
      background-color: cv("danger");
    }

    &-content {
      padding: 20px 30px 30px;
      background-color: cv("danger");
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      margin-top: 1px;
      padding: 0 30px;
      background-color: cv("danger");
    }
  }
}
