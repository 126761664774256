@use "@glsass/tools/tools.global" as *;

.l-toolbar {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 60px;
  background-color: cv("white");
  border-bottom: 1px solid cv("gray", E00);
  transition: background-color 0.35s ease;

  &--dark {
    color: cv("gray", B00);
    background-color: cv("black");
    border-bottom-width: 0;
  }
}
