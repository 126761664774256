////
/// @group Components
/// Form validations
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-file {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  height: 40px;
  overflow: hidden;
  border-radius: graphics.$ui-border-radius;

  &__text {
    // span
    display: flex;
    flex-grow: 1;
    align-items: center;
    min-width: 2rem;
    margin-right: 1px;
    padding: 0 20px;
    color: cv("white");

    text-decoration: underline;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: graphics.$ui-form-background-color;
    border: 1px solid graphics.$ui-form-border-color;
    border-top-left-radius: graphics.$ui-border-radius;
    border-bottom-left-radius: graphics.$ui-border-radius;
  }

  &__btn {
    // strong, a
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    color: cv("white");
    background-color: cv("gray", 600);
    border-top-right-radius: graphics.$ui-border-radius;
    border-bottom-right-radius: graphics.$ui-border-radius;
    transition: background-color 0.35s graphics.$ui-transition-timing-function;

    &:hover {
      background-color: cv("gray", 500);
    }
  }
}
