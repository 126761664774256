////
/// @group Components
/// Filters toggle
////

@use "@glsass/tools/tools.global" as *;

.c-filters-toggle {
  $root: &;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: inline-block;
    background-color: cv("gray", 300);
    opacity: 0;
    pointer-events: none;
  }

  &__txt {
    position: relative;
    z-index: 2;
    padding: 22px 0;
    cursor: pointer;

    span {
      color: cv("purple");
      font-weight: bold;

      .t-dark & {
        color: cv("primary");
      }
    }

    &::after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 7px;
      margin-left: 10px;
      background-color: currentColor;
      mask-image: url(../../images/icons/arrow-bottom.svg);
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      transition: transform 0.35s ease;
    }
  }

  &.is-open {
    color: cv("white");

    &::before {
      opacity: 1;
      pointer-events: auto;
    }

    #{$root}__txt {
      span {
        color: cv("white");
      }

      &::after {
        background-color: cv("white");
        transform: rotate(-180deg);
      }
    }
  }
}
