////
/// @group Components
/// Form validations
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.color" as _color;
@use "@glsass/tools/tools.form" as form;
@use "@glsass/tools/tools.string" as _str;
@use "@glsass/tools/tools.vhd" as vhd;
@use "@glsass/tools/tools.global" as *;

/// Forward Glsass form-components
@forward "@glsass/components/forms/forms.main";
@forward "@glsass/components/forms/forms.text" with($c-text-margin-top: 0.5rem);
@forward "@glsass/components/forms/forms.label" with (
  $c-label-margin-bottom: 10px,
  $c-label-color: cv("gray", B00)
);
@forward "@glsass/components/forms/forms.control" with (
  $c-control-height: 40px,
  $c-control-padding: 10px 20px,
  // $c-control-text-disabled: rgba(cv("white"), 0.5),
  $c-control-background-disabled: rgba(cv("gray", 600), 0.3)
);
@forward "@glsass/components/forms/forms.select" with (
  $c-select-height: 40px,
  $c-select-padding: 10px 20px,
  $c-select-text-disabled: rgba(cv("white"), 0.5),
  $c-select-background-disabled: rgba(cv("gray", 600), 0.3),
  $c-select-icon:
    _str.escape-svg(
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'><path fill='#eee' d='M5 5L0 0h10z' /></svg>"
    )
);

/// Custom components
/// Check
.c-form__check {
  $root-check: &;

  display: flex;
  align-items: center;
  width: 100%;

  &-input {
    display: block;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    background-color: cv("gray", 400);
    border: 1px solid cv("gray", 500);
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      background-color 0.15s ease-in-out;
    appearance: none;

    &:focus {
      // border-color: graphics.$ui-form-focus-border-color;
      outline: 0;
      box-shadow: 0 0 0 graphics.$ui-form-focus-shadow-width
        graphics.$ui-form-focus-shadow-color;
    }

    &:active:not(:disabled):not(.disabled) {
      filter: brightness(90%);
    }

    &:disabled {
      cursor: not-allowed;

      &:checked {
        opacity: 0.5;
      }

      ~ #{$root-check}-label {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }

    &:checked {
      background-color: cv("gray", 600);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 20px auto;

      ~ #{$root-check}-label {
        font-weight: 700;
      }
    }

    &[type="checkbox"] {
      &:checked {
        background-image: url(iv(
          "check",
          $options: (currentColor: cv(success))
        ));
      }
    }

    &[type="radio"] {
      border-radius: 50%;

      &:checked {
        background-image: url(iv(radio));
      }
    }
  }

  &-label {
    padding-left: 15px;
    color: cv("white");
    user-select: none;
  }
}

/// Switch
.c-form__switch {
  $root-switch: &;

  display: flex;
  align-items: center;

  &-input {
    display: block;
    flex-shrink: 0;
    width: 40px;
    height: 20px;
    background-color: cv("gray", 400);
    background-image: url(iv(
      "circle",
      $options: (currentColor: cv("gray", B00))
    ));
    background-repeat: no-repeat;
    background-position: center left 2px;
    background-size: 1rem;
    border-radius: 20px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      background 0.15s ease-in-out;
    appearance: none;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 graphics.$ui-form-focus-shadow-width
        graphics.$ui-form-focus-shadow-color;
    }

    &:active:not(:disabled):not(.disabled) {
      filter: brightness(90%);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;

      ~ #{$root-switch}-label {
        cursor: not-allowed;
        opacity: 0.3;
      }
    }

    &:checked {
      background-image: url(iv(
        "circle",
        $options: (currentColor: cv("success"))
      ));
      background-position: center right 2px;
    }
  }

  &-label {
    padding-left: 10px;
    color: cv("white");
    font-weight: 700;
    user-select: none;
  }
}

// File
.c-form__file {
  $root-file: &;

  width: 100%;

  &--red {
    #{$root-file}-label {
      strong {
        color: cv("gray", 300);
        background-color: cv("gray", B00);
      }

      span {
        color: cv("white");
        background-color: _color.tint(cv("danger"), 6);
        border-color: _color.tint(cv("danger"), 6);
      }
    }
  }

  &-input {
    @include vhd.visually-hidden();
    pointer-events: none;

    &:focus {
      ~ #{$root-file}-label {
        outline: 0;
        box-shadow: 0 0 0 graphics.$ui-form-focus-shadow-width
          graphics.$ui-form-focus-shadow-color;
      }
    }

    &:disabled {
      ~ #{$root-file}-label {
        cursor: not-allowed;
      }
    }
  }

  &-label {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    height: 40px;
    overflow: hidden;
    color: cv("black");
    border-radius: graphics.$ui-border-radius;

    strong,
    span {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 20px;
      pointer-events: none;
    }

    strong {
      flex-shrink: 0;
      margin-left: 1px;
      color: cv("white");
      text-transform: uppercase;
      background-color: cv("gray", 600);
      border-top-right-radius: graphics.$ui-border-radius;
      border-bottom-right-radius: graphics.$ui-border-radius;
    }

    span {
      flex-grow: 1;
      min-width: 2rem;
      overflow: hidden;
      color: cv("gray", B00);
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: graphics.$ui-form-background-color;
      border: 1px solid graphics.$ui-form-border-color;
      border-top-left-radius: graphics.$ui-border-radius;
      border-bottom-left-radius: graphics.$ui-border-radius;
    }
  }
}

/// Icon
.c-form__icon {
  position: absolute;
  top: 12px;
  right: 20px;
}

/// Number
.c-form__number {
  $root-number: &;

  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: graphics.$ui-border-radius;

  &:focus-within {
    box-shadow: 0 0 0 graphics.$ui-form-focus-shadow-width
      graphics.$ui-form-focus-shadow-color;
  }

  input[type="number"] {
    padding-right: 40px;
    -moz-appearance: textfield; // Firefox

    // Chrome/Edge + Safari
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }

  &--with-increment {
    input[type="number"] {
      flex-shrink: 1;
      width: 100%;
      padding-right: 30px;
      padding-left: 10px;
      border-radius: 0;

      &:focus {
        box-shadow: none;
      }
    }

    #{$root-number}-unit {
      right: 50px;
    }
  }

  &-unit {
    position: absolute;
    top: 50%;
    right: 10px;
    color: cv("gray", B00);
    transform: translateY(-50%);
  }
}

@include form.add-validation-states(
  graphics.$ui-form-states,
  ".c-form__number",
  graphics.$ui-form-focus-shadow-width
);

/// Increment (number)
.c-form__increment {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: cv("white");
  font-size: 16px;
  font-weight: bolder;
  line-height: 1;
  background: cv("gray", 600);
  cursor: pointer;
  user-select: none;

  &[disabled="disabled"] {
    opacity: 0.5;
    pointer-events: none;
  }

  &-less {
    margin-right: 1px;
    border-top-left-radius: graphics.$ui-border-radius;
    border-bottom-left-radius: graphics.$ui-border-radius;
  }

  &-more {
    margin-left: 1px;
    border-top-right-radius: graphics.$ui-border-radius;
    border-bottom-right-radius: graphics.$ui-border-radius;
  }
}

// Search / Autocomplete
.c-form__search {
  $root-search: &;

  position: relative;
  // display: flex;
  width: 100%;
  border-radius: graphics.$ui-border-radius;

  &:focus-within {
    box-shadow: 0 0 0 graphics.$ui-form-focus-shadow-width
      graphics.$ui-form-focus-shadow-color;
  }

  &::after {
    content: "";
    position: absolute;
    top: calc((40px - 1rem) / 2);
    right: 20px;
    width: 1rem;
    height: 1rem;
    background-color: cv("white");
    mask-image: url(iv(search));
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
  }

  &.is-loading {
    &::after {
      mask-image: url(iv("loading"));
      animation-name: loading;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  .suggestions {
    margin-top: 1px;
    background-color: cv("gray", 600);
    border-bottom-right-radius: graphics.$ui-border-radius;
    border-bottom-left-radius: graphics.$ui-border-radius;
  }

  .empty-item,
  .suggest-item {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    color: cv("gray", C00);
  }

  .suggest-item {
    color: cv("white");
    cursor: pointer;
    user-select: none;

    &.hover {
      background-color: cv("gray", 700);

      &:not(:first-child) {
        &::before {
          content: "";
          position: absolute;
          top: -1px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: cv("gray", 700);
        }
      }
    }

    &:last-child {
      border-bottom-right-radius: graphics.$ui-border-radius;
      border-bottom-left-radius: graphics.$ui-border-radius;
    }

    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 20px;
        width: calc(100% - 40px);
        height: 1px;
        background-color: cv("gray", 700);
      }
    }
  }

  .focus {
    input {
      background-color: cv("gray", 600);
    }

    [aria-expanded="true"] input {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  input {
    width: 100%;

    &:focus {
      box-shadow: none;
    }
  }
}

/// Calendar / Datepicker
.c-form__calendar {
  $root-calendar: &;

  position: relative;
  display: flex;
  width: 100%;

  &-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 1rem;
    height: 1rem;
    object-fit: contain;
    transform: translateY(-50%);
  }
}

@include form.add-validation-states(
  graphics.$ui-form-states,
  ".c-form__calendar",
  graphics.$ui-form-focus-shadow-width
);

/// Form validations
.c-form__control {
  &:not(:placeholder-shown):not(.is-invalid):not(:disabled):not([readonly]) {
    background-color: cv("gray", 600);
  }

  &.is-invalid {
    color: cv(danger);

    &:focus {
      color: cv("white");
      background-color: cv("gray", 400);
    }
  }
}

// Normalize input for logistic
.c-form__input {
  display: block;
  flex: 1 0 auto;
  width: 100%;
  height: 30px;
  padding: 10px;
  color: cv("black");
  font-size: 12px;
  font-weight: 700;
  text-align: right;
  background: transparent;
  border: 1px solid cv("gray", C00);
  border-radius: graphics.$ui-border-radius;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: cv("gray", B00);
    outline: 0;
    box-shadow: 0 0 0 graphics.$ui-form-focus-shadow-width
      graphics.$ui-form-focus-shadow-color;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
  }

  &[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
}

/// Overrides
textarea.c-form__control {
  height: 60px;
  min-height: 60px;
  resize: vertical;

  &--large {
    height: 120px;
    resize: none;
  }

  &--red {
    height: 200px;
    color: cv("white");
    background-color: _color.tint(cv("danger"), 6);
    border-color: _color.tint(cv("danger"), 6);
    resize: vertical;

    &::placeholder {
      color: cv("gray", E00);
    }

    &:hover:not(:disabled):not([readonly]):not([class*="is-"]) {
      border-color: _color.tint(cv("danger"), 6);
    }

    &:focus {
      border-color: _color.tint(cv("danger"), 6);
      box-shadow: 0 0 0 0.25rem rgb(cv("white"), 0.25);
    }

    &:not(:placeholder-shown):not(.is-invalid):not(:disabled):not([readonly]) {
      background-color: _color.tint(cv("danger"), 6);
    }
  }
}

.c-form__control,
.c-form__select {
  &:disabled,
  &[readonly] {
    color: rgba(cv("white"), 0.5);
    border-color: transparent;
  }
}

.c-form__select {
  max-width: 100%;

  &:not([multiple]):not([size]) {
    padding-right: 40px;
    background-position: right 20px center;
    background-size: 10px 5px;
  }

  &.is-empty {
    color: graphics.$ui-form-placeholder-color;
  }

  &:not(:required):not(.is-empty),
  &:required:valid {
    background-color: cv("gray", 600);
  }

  // increase default option font-size to ~1rem
  option {
    font-size: 1.2em;
  }
}

.c-form__feedback,
.c-form__text {
  line-height: 1;
}
