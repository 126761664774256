////
/// @group Components
/// Breadcrumb
////

@use "@glsass/tools/tools.global" as *;

.c-download {
  display: flex;
  align-items: center;
  color: cv("white");
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;

    &::before {
      background-color: cv("gray", E00);
    }
  }

  &::before {
    content: "";
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    margin: 0 10px 0 30px;
    background-color: cv("white");
    background-image: url(iv(
      "download",
      $options: (currentColor: cv(primary))
    ));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px auto;
    border-radius: 50%;
  }

  &--icon {
    &::before {
      margin: 0;
    }
  }
}
