////
/// @group Modules
/// ORR - Order Reference Retail
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.m-orr {
  $_root-orr: &;

  position: relative;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &[open] {
    #{$_root-orr}__title::after {
      transform: rotate(-0.5turn);
    }
  }

  &__title {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 10px;
    color: cv("white");
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    background-image: linear-gradient(to right, cv("purple"), cv("primary"));
    cursor: pointer;
    user-select: none;

    &::marker {
      display: none;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-left: auto;
      background-color: currentColor;
      mask-image: url(iv(chevron));
      transition: transform 0.35s graphics.$ui-transition-timing-function;
    }

    #{$_root-orr}__action {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(calc(100% + 20px), -50%);

      &--pl {
        transform: translate(calc(100% + 30px), -50%);
      }
    }
  }
}
