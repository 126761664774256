////
/// @group Components
/// Button chevron
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.vhd" as vhd;
@use "@glsass/tools/tools.global" as *;

.c-button-chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: cv("primary");
  background-image: linear-gradient(to right, cv("purple"), cv("primary"));
  border: 0;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(cv("black"), 0.15);
  cursor: pointer;
  transition: all 0.35s graphics.$ui-transition-timing-function;

  &:hover,
  &:focus {
    outline: 0;
    box-shadow: 0 3px 6px rgba(cv("black"), 0.15),
      0 0 0 4px rgba(cv("primary"), 0.25);
  }

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: cv("white");
    mask-image: url(iv(chevron));
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    transition: all 0.35s graphics.$ui-transition-timing-function;
  }

  &.is-active,
  &.is-open {
    background-image: linear-gradient(to right, cv("white"), cv("white"));

    &::before {
      background-image: linear-gradient(to right, cv("purple"), cv("primary"));
      transform: rotate(-0.5turn);
    }
  }

  &--light {
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 4px rgba(cv("white"), 0.2);
    }
  }

  span {
    @include vhd.visually-hidden();
  }
}
