////
/// @group Components
/// Slider
////

@use "@glsass/tools/tools.global" as *;

.c-slider {
  display: flex;
  flex-grow: 1;
  align-items: center;

  &__dot {
    width: 100%;
    height: 100%;
    background-color: cv("white");
    border-radius: 50%;
    transition: all 0.3s;
  }

  &__progress {
    background: cv("primary");
    background: linear-gradient(
      -90deg,
      cv("primary") 0%,
      cv("primary") 50%,
      cv("purple") 100%
    );
  }

  &__text {
    margin-left: 5px;
    color: cv("white");
    font-size: 10px;
    font-weight: 700;
  }
}
