////
/// @group Components
/// Priority
////

@use "@glsass/tools/tools.global" as *;

.c-priority {
  display: inline-block;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  color: cv(primary);
  font-size: 14px;
  font-weight: 900;
  line-height: 40px;
  text-align: center;
  background-color: cv("white");
  border: 1px solid cv("gray", E00);
  border-radius: 50%;
}
