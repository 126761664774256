////
/// @group Components
/// Action
////

@use "sass:math";

@use "../settings" as core;

@use "@glsass/tools/tools.global" as *;

@use "@glsass/graphics";

.c-action {
  $_root-action: &;
  $_internal-padding: 20px;

  &s {
    display: flex;
    align-items: center;
    margin-left: -$_internal-padding;

    #{$_root-action} {
      &:not(:last-child) {
        &:focus {
          &::after {
            background-color: transparent;
          }
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          display: block;
          width: 1px;
          height: 1rem;
          background-color: cv("gray", E00);
          transform: translateY(-50%);
        }
      }
    }
  }

  /* stylelint-disable-next-line order/order */
  position: relative;
  display: flex;
  align-items: center;
  padding: $_internal-padding;
  color: cv("gray", 700);
  font-size: 12px;
  line-height: math.div(20, 12);
  text-decoration: none;
  text-transform: uppercase;
  background: none;
  border: 0;
  cursor: pointer;
  transition: color 0.35s graphics.$ui-transition-timing-function;

  &:hover,
  &:focus {
    color: cv("purple");

    &::before {
      background-color: currentColor;
    }
  }

  &:focus {
    outline: 0;
    box-shadow: inset 0 0 0 0.125rem rgba(cv("purple"), 25%);
  }

  &:disabled,
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &::before {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 10px;
    background-color: cv("gray", 700);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    transition: background-color 0.35s graphics.$ui-transition-timing-function;
  }

  &[class*="is-"] {
    &::before {
      content: "";
    }
  }

  &.is-add::before {
    mask-image: url(iv("add"));
  }

  &.is-confirm::before {
    mask-image: url(iv("confirm"));
  }

  &.is-edit::before {
    mask-image: url(iv("edit"));
  }

  &.is-export::before {
    mask-image: url(iv("export"));
  }

  &.is-file::before {
    mask-image: url(iv("file"));
  }

  &.is-import::before {
    mask-image: url(iv("import"));
  }

  &.is-kit::before {
    mask-image: url(iv("kit"));
  }

  &.is-book::before {
    mask-image: url(iv("book"));
  }

  &.is-open::before {
    mask-image: url(iv("open"));
  }

  &.is-send::before {
    mask-image: url(iv("send"));
  }
}
