////
/// @group Components
/// Table heading
////

@use "@glsass/tools/tools.global" as *;

@use "@glsass/graphics";

.c-table-heading {
  $_root-heading: &;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 2px solid cv("black");

  &--white {
    border-bottom-color: cv("white");

    #{$_root-heading}__title {
      color: cv("white");
    }
  }

  &__title {
    margin: 0;
    color: cv("black");
    font-size: 26px;
    font-weight: 900;
  }

  &__tabs {
    display: flex;
    margin-bottom: -10px;
  }

  &__tab {
    padding: 11px 20px;
    color: #707070;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    background-color: rgba(cv("black"), 0.1);
    transition: background-color 0.35s graphics.$ui-transition-timing-function;

    &:not(:last-child) {
      margin-right: 1px;
    }

    &:hover {
      background-color: rgba(cv("black"), 0.15);
    }

    &.is-active {
      color: cv("white");
      background-color: cv("black");
    }
  }

  &__counters {
    display: flex;
    margin: 0;
  }

  &__counter {
    margin: 0;
    padding: 0;
    color: cv("gray", 700);
    list-style: none;

    &::before {
      content: "|";
      padding: 0 20px;
    }

    strong {
      color: cv("black");
    }

    &:first-child {
      &::before {
        display: none;
      }
    }
  }
}
