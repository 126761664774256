////
/// @group Components
/// Chip
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-check {
  $_root-check: &;

  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: cv("gray", 400);
  border: 1px solid cv("gray", 500);
  border-radius: graphics.$ui-border-radius;
  cursor: pointer;
  transition: all 0.35s graphics.$ui-transition-timing-function;
  appearance: none;

  &::after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background: cv("gray", 700);
    mask-image: url(iv("check"));
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    transition: background-color 0.35s graphics.$ui-transition-timing-function;
  }

  &:hover {
    background-color: cv("gray", 500);

    &::after {
      background-color: cv("gray", 900);
    }
  }

  &:focus {
    background-color: cv("gray", 500);
    outline: 0;
    box-shadow: 0 0 0 graphics.$ui-form-focus-shadow-width
      graphics.$ui-form-focus-shadow-color;

    &::after {
      background-color: cv("gray", 900);
    }
  }

  &:active:not(:disabled):not(.disabled) {
    filter: brightness(90%);
  }

  &:disabled,
  &.is-disabled,
  &.disabled {
    cursor: not-allowed;

    &:checked {
      opacity: 0.5;
    }
  }

  &:checked,
  &.checked,
  &.is-checked {
    background-color: cv("success");
    border-color: cv("success");

    &::after {
      background-color: cv("white");
    }
  }
}
