@forward "@glsass/elements/elements.page";

html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

// body {
//   min-height: 100%;
// }

*,
*::before,
*::after {
  box-sizing: border-box;
}
