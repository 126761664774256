@use "@glsass/tools/tools.global" as *;

.c-targeting-video-filter {
  display: flex;
  align-items: center;
  color: cv("gray", 700);

  div {
    padding: 0 10px;
    border-right: 1px solid cv("gray", 300);
    cursor: pointer;

    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    &.active,
    &:hover {
      color: cv("white");
      font-weight: 700;
    }
  }
}
