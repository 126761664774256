////
/// @group Components
/// Nav
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-nav {
  $root: &;

  position: absolute;
  bottom: 0;

  display: flex;
  margin-bottom: 0;
  list-style: none;

  &__item {
    display: flex;
    height: 50px;
    margin-right: 1px;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    color: cv("gray", B00);
    text-decoration: none;
    background-color: cv("gray", 400);
    transition: all 0.35s graphics.$ui-transition-timing-function;

    &:hover,
    &:focus {
      color: cv("gray", E00);
      background-color: cv("gray", 600);
    }

    &:focus {
      outline: 0;
      box-shadow: inset 0 0 0 0.125rem rgba(cv("white"), 25%);
    }

    &.is-active {
      color: cv("black");
      font-weight: 700;
      background-color: cv("white");
    }
  }

  &--tab {
    #{$root}__item {
      height: 40px;
    }

    #{$root}__link {
      color: cv("gray", 700);
      background-color: rgba(cv("black"), 0.1);

      &.is-active {
        color: cv("white");
        background-color: cv("black");
      }
    }
  }
}
