////
/// @group Modules
/// Notification button
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.m-notif {
  position: relative;
  display: grid;
  place-items: center;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: cv("gray", E00);
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.35s graphics.$ui-transition-timing-function;

  &::before {
    content: "";
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-color: cv("gray", 400);
    mask-image: url(iv("bell"));
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    transition: background-color 0.35s graphics.$ui-transition-timing-function;
  }

  &:hover::before,
  &.is-active::before {
    background-color: cv("primary");
  }

  &__counter {
    position: absolute;
    top: -5px;
    right: -5px;
    display: grid;
    place-items: center;
    min-width: 14px;
    height: 14px;
    padding: 0 3px;
    color: cv("white");
    font-size: 9px;
    line-height: 1;
    background-color: cv("primary");
    border-radius: 4px;
  }
}
