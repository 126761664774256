@use "./assets/sass/sfr" as sfr;
@charset "UTF-8";

@use "settings";

// TOOLS
@use "@glsass/tools/tools.global" as *;

// GENERICS
@forward "@glsass/generics/generics.reset";
@forward "@glsass/generics/generics.shared";

@forward "generics/generics.fonts";

// ELEMENTS
@forward "elements/elements.page";

// OJBECTS
@forward "@glsass/objects/objects.layout";
@forward "@glsass/objects/objects.link";
@forward "@glsass/objects/objects.list-inline";

@forward "objects/objects.header";

// LAYOUTS
@forward "layouts/layouts.actions";
@forward "layouts/layouts.app";
@forward "layouts/layouts.footer";
@forward "layouts/layouts.form";
@forward "layouts/layouts.header";
@forward "layouts/layouts.hero";
@forward "layouts/layouts.main";
@forward "layouts/layouts.modal";
@forward "layouts/layouts.toolbar";

// COMPONENTS
@forward "@glsass/components/components.avatar" with (
  $c-avatar-size: 30px
);
@forward "@glsass/components/components.badge" with (
  $c-badge-font-size: 10px,
  $c-badge-line-height: 1,
  $c-badge-padding-y: 5px,
  $c-badge-padding-x: 10px,
  $c-badge-border-radius: 5px
);
@forward "@glsass/components/components.tooltip" with (
  $c-tooltip-color-background: cv("white")
);

@forward "components/components.accordion";
@forward "components/components.action";
@forward "components/components.actions-menu";
@forward "components/components.alert";
@forward "components/components.anchor";
@forward "components/components.area.mini";
@forward "components/components.board";
@forward "components/components.breadcrumb";
@forward "components/components.button";
@forward "components/components.button-check";
@forward "components/components.button-chevron";
@forward "components/components.button-status";
@forward "components/components.callout";
@forward "components/components.campaign.card";
@forward "components/components.card";
@forward "components/components.changelog";
@forward "components/components.chartpie";
@forward "components/components.check";
@forward "components/components.chip";
@forward "components/components.download";
@forward "components/components.file";
@forward "components/components.filters";
@forward "components/components.filters-toggle";
@forward "components/components.form";
@forward "components/components.form.footer";
@forward "components/components.gradient-title";
@forward "components/components.icon";
@forward "components/components.lead";
@forward "components/components.logo";
@forward "components/components.modal";
@forward "components/components.modal.add-targeting";
@forward "components/components.nav";
@forward "components/components.navbar";
@forward "components/components.pagination";
@forward "components/components.position";
@forward "components/components.position.card";
@forward "components/components.priority";
@forward "components/components.profile";
@forward "components/components.progress";
@forward "components/components.red-warning";
@forward "components/components.reference";
@forward "components/components.ref-mini";
@forward "components/components.report";
@forward "components/components.searchbar";
@forward "components/components.select-filter";
@forward "components/components.sidebar";
@forward "components/components.slider";
@forward "components/components.status";
@forward "components/components.step";
@forward "components/components.stonk";
@forward "components/components.subtable";
@forward "components/components.table";
@forward "components/components.tablenext";
@forward "components/components.table-heading";
@forward "components/components.tag";
@forward "components/components.target-table";
@forward "components/components.title";
@forward "components/components.toast";
@forward "components/components.tooltip";
@forward "components/components.upload";
@forward "components/components.validation";
@forward "components/components.viewer";
@forward "components/components.warning";
@forward "components/components.wysiwyg";
@forward "components/components.spinner";
@forward "components/components.targeting-video-filter";
@forward "components/components.reference-search";

// MODULES
@forward "modules/modules.alert";
@forward "modules/modules.illu";
@forward "modules/modules.line";
@forward "modules/modules.modal";
@forward "modules/modules.notif";
@forward "modules/modules.orr";
@forward "modules/modules.showcase";
@forward "modules/modules.table";

// HELPERS
@forward "@glsass/helpers/helpers.clearfix";
@forward "@glsass/helpers/helpers.links";
@forward "@glsass/helpers/helpers.ratio";

// UTILITIES
@forward "utilities";

.vm--modal {
  border-radius: 0 !important;
}

// Overrides
.c-badge {
  flex-shrink: 0;
}

.c-breadcrumb {
  overflow-x: auto;
}

.c-tooltip::after {
  box-shadow: 0 0 4px rgba(cv("black"), 0.25);
}
