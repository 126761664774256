////
/// @group Components
/// Actions menu
////

@use "@glsass/tools/tools.global" as *;

@use "@glsass/graphics";

.c-actions-menu {
  $root: &;
  position: relative;
  z-index: 100;

  &::before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    text-align: center;
    background-color: cv("gray", D00);
    background-image: url(iv(
      "dots",
      $options: ("currentColor": cv("gray", 700))
    ));
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 4px;
    border: 1px solid cv("gray", C00);
    border-radius: graphics.$ui-border-radius;
    cursor: pointer;
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    width: 180px;
    background-color: cv("gray", 300);
    border-radius: graphics.$ui-border-radius;
    box-shadow: 0 3px 6px rgba(cv("black"), 0.15);
  }

  &__title {
    margin: 0;
    padding: 8px 20px;
    color: cv("gray", B00);
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid cv("white");
  }

  &__links {
    padding: 0 20px;
  }

  &__link {
    display: block;
    padding: 12px 0;
    color: cv("white");
    font-size: 14px;
    text-decoration: none;
    border-bottom: 1px solid cv("gray", 700);
    cursor: pointer;
    transition: color 0.35s ease;

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      color: cv("primary");
    }
  }

  &:hover {
    #{$root}__content {
      display: block;
    }
  }
}
