////
/// @group Components
/// Gradient title
////

@use "sass:math";

@use "../settings" as core;

@use "@glsass/tools/tools.breakpoint" as breakpoint;
@use "@glsass/tools/tools.global" as *;

$_o-layout-gutter-spacing: math.div(core.$g-grid-gutter-width, 2);

.c-gradient-title {
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  padding-right: 36px;
  color: cv("white");
  font-size: 14px;
  text-transform: uppercase;
  background: rgb(196, 4, 121);
  background: linear-gradient(
    -90deg,
    rgba(196, 4, 121, 1) 0%,
    rgba(196, 4, 121, 1) 50%,
    rgba(124, 41, 125, 1) 100%
  );
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;

  &__action {
    position: absolute;
    top: 50%;
    right: -2rem;
    padding: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
    transform: translateY(-50%);
    appearance: none;

    &.is-delete {
      width: 1rem;
      height: 1rem;
      background-color: cv("white");
      mask-image: url(iv("delete"));
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
    }
  }
}

@each $breakpoint, $value in core.$g-grid-container-max-widths {
  @include breakpoint.media-up($breakpoint) {
    .c-gradient-title {
      margin-left: calc(-50vw + #{$value}/ 2 - #{$_o-layout-gutter-spacing});
      padding-left: calc(50vw - #{$value}/ 2 + #{$_o-layout-gutter-spacing});
    }
  }
}
