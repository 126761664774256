////
/// @group Components
/// Tooltip
////

@use "../settings" as core;

@use "@glsass/tools/tools.contrast" as contrast;
@use "@glsass/tools/tools.global" as *;

// Overrides
.c-tooltip {
  &::after {
    text-align: center;
  }

  &-warning::after {
    width: max-content;
    max-width: 230px;
    padding: 10px;
    color: cv("white");
    font-size: 12px;
    font-weight: 700;
    white-space: unset;
    background: cv(danger);
  }

  &--warning::after {
    color: contrast.color(cv("warning"));
    background: cv("warning");
  }
}
