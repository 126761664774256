////
/// @group Modules
/// Showcase
////

@use "../settings" as core;

@use "@glsass/tools/tools.string" as _str;
@use "@glsass/tools/tools.global" as *;

@use "@glsass/graphics";

$_mask: _str.escape-svg(
  "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 539 350'>\
    <path d='M50 0h489v350H0z'/>\
  </svg>"
);

$_mask_campaign: _str.escape-svg(
  "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 400 350'>\
    <path d='M50 0h489v350H0z'/>\
  </svg>"
);

.m-showcase {
  $root-showcase: &;

  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 540px;
  height: 100%;
  background-color: rgba(cv("black"), 0.2);
  background-image: url("../../images/hero/store.svg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 300px auto;
  mask-image: url($_mask);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;

  &--campaign {
    width: 400px;
    background-image: url("../../images/hero/campaign.svg");
    background-position: center bottom -10px;
    background-size: 250px auto;
    mask-image: url($_mask_campaign);
    mask-position: bottom left;
    mask-size: auto 100%;

    #{$root-showcase}__image {
      cursor: default;

      &::before,
      &::after {
        content: none;
      }
    }
  }

  &__image {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: transparent;
      transition: background-color 0.35s graphics.$ui-transition-timing-function;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
      width: 40px;
      height: 40px;
      background-image: url(iv(search, $options: (currentColor: cv("white"))));
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: opacity 0.35s graphics.$ui-transition-timing-function;
    }

    &:hover {
      &::before {
        background-color: rgba(cv("black"), 0.5);
      }

      &::after {
        opacity: 1;
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__edit {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 40px;
    height: 40px;
    overflow: hidden;
    color: transparent;
    background-color: rgba(cv("white"), 0.7);
    background-image: url(iv(edit, $options: (currentColor: cv("gray", 700))));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
    cursor: pointer;

    &:hover {
      background-color: cv("white");
    }
  }
}
