////
/// @group Components
/// Viewer
////

@use "@glsass/tools/tools.global" as *;

.c-viewer-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  width: 100%;
  height: 100%;
}

.c-viewer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: cv("black");

  &__images {
    position: absolute;
    z-index: 1;
    width: 70px;
    height: 100%;
    margin: 0;
    padding-bottom: 60px;
    overflow-y: auto;
    background: cv("black");
  }

  &__wrapper {
    width: 50px;
    height: 50px;
    margin: 10px;

    &.is-active {
      border: 1px solid cv("white");
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  &__controllers {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 30px;
    background-color: cv("black");
    border-top: 1px solid cv("gray", 200);
  }

  &__video {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 60px);
  }

  &__player {
    width: 100%;
  }

  &__pdf {
    width: 100%;
    height: calc(100% - 60px);
  }
}
