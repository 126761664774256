////
/// @group Components
/// Status indicator
////

@use "@glsass/tools/tools.global" as *;

.c-stonk {
  display: inline-flex;
  align-items: center;

  &::after {
    width: 12px;
    height: 10px;
    margin-left: 10px;
    background-color: currentColor;
    mask-image: url(iv("arrow-right"));
    mask-repeat: no-repeat;
    mask-position: center;
  }

  &--up::after {
    content: "";
    background-color: cv("success");
    transform: rotate(-90deg);
  }

  &--down::after {
    content: "";
    background-color: cv("danger");
    transform: rotate(90deg);
  }
}
