////
/// @group Components
/// Filters
////

@use "@glsass/tools/tools.global" as *;

.c-filters {
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  z-index: 200;
  display: none;
  background-color: cv("gray", 300);
  box-shadow: 0 3px 6px rgba(cv("black"), 0.3);

  &.is-open {
    display: block;
  }

  &__group {
    margin: 30px 0 20px 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid cv("gray", 700);
  }

  &__reset {
    display: block;
    flex-grow: 0;
    width: 21px;
    height: 21px;
    margin-left: 10px;
    background-color: transparent;
    background-image: url(../../images/icons/clear-filters-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    cursor: pointer;
    opacity: 0.3;
    transition: opacity 0.35s ease;

    &--all {
      position: relative;
      top: -40px;
      float: right;
      margin-bottom: -20px;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &__title {
    margin: 0;
    padding: 0;
    color: cv("gray", B00);
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    text-transform: uppercase;
  }

  &__section {
    &__header {
      padding: 10px 0;
      background-color: cv("gray", 200);
      border-bottom: 1px solid cv("gray", 300);
    }
  }
}
