////
/// @group Components
/// Nav
////

@use "sass:math";

@use "@glsass/tools/tools.global" as *;

@use "@glsass/graphics";

.c-profile {
  $root: &;

  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background-color: cv("gray", 300);
    border-bottom: 1px solid cv("white");
    border-radius: graphics.$ui-border-radius;
    transform: translate(-50%, -50%);
  }

  &.is-active {
    &::before {
      content: "";
    }

    #{$root}__infos {
      color: cv("white");
    }
  }

  &:hover {
    &::before {
      content: "";
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    #{$root}__infos {
      color: cv("white");
    }

    #{$root}__links {
      display: block;
    }
  }

  &__infos {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 12px;
    font-weight: 700;
    line-height: math.div(14, 12);
  }

  &__team {
    font-size: 10px;
    line-height: math.div(14, 10);
  }

  &__links {
    position: absolute;
    top: calc(100% + 11px);
    left: 50%;
    display: none;
    width: calc(100% + 20px);
    padding: 0 10px;
    background-color: cv("gray", 300);
    border-bottom-right-radius: graphics.$ui-border-radius;
    border-bottom-left-radius: graphics.$ui-border-radius;
    box-shadow: 0 3px 6px rgba(cv("black"), 0.15);
    transform: translateX(-50%);

    a {
      display: block;
      padding: 10px 0;
      color: cv("gray", B00);
      font-size: 12px;
      line-height: math.div(14, 12);
      text-decoration: none;
      text-transform: uppercase;

      &:not(:last-child) {
        border-bottom: 1px solid cv("gray", 700);
      }

      &:hover {
        color: cv("white");
      }
    }
  }
}
