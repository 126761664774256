////
/// @group Components
/// Alert
////

@use "sass:math";

@use "@glsass/tools/tools.global" as *;

.c-title {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 30px;
  color: cv("white");
  font-size: 22px;
  font-weight: 700;
  line-height: math.div(25, 22);

  &::after {
    content: "";
    display: block;
    flex: 1 0 auto;
    order: 1;
    height: 4px;
    margin-left: 30px;
    background-color: rgba(cv("white"), 0.1);
    transform: skew(-45deg);
  }

  &--alt {
    color: cv("black");
    font-size: 18px;

    &::after {
      margin-left: 20px;
      background-color: rgba(cv("white"), 0.7);
    }
  }

  &__action {
    position: absolute;
    top: 50%;
    right: -2rem;
    padding: 0;
    border: 0;
    cursor: pointer;
    transform: translateY(-50%);
    appearance: none;

    &.is-delete {
      width: 1rem;
      height: 1rem;
      background-color: cv("white");
      mask-image: url(iv("delete"));
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
    }
  }
}
