////
/// @group Utilities
/// Custom utils
////

@use "../settings" as core;

@use "@glsass/tools/tools.global" as *;

//opacity
.u-opacity {
  opacity: 1;

  &__80 {
    opacity: 0.8;
  }
}

//background
.u-bg {
  &-gradient {
    background: rgb(196, 4, 121);
    background: linear-gradient(
      -90deg,
      rgba(196, 4, 121, 1) 0%,
      rgba(196, 4, 121, 1) 50%,
      rgba(124, 41, 125, 1) 100%
    );
  }
}

//radius
.u-radius {
  overflow: hidden;
  border-radius: 0;

  &__10 {
    border-radius: 10px;
  }

  &__full {
    border-radius: 100%;
  }

  &-tr {
    border-top-right-radius: 0;
  }

  &-tl {
    border-top-left-radius: 0;
  }

  &-br {
    border-bottom-right-radius: 0;
  }

  &-bl {
    border-bottom-left-radius: 0;
  }
}

.u-bd {
  &-r {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: cv("gray", "400");
  }
}

//texts
.u-text {
  font-size: 12px;
  font-weight: 400;

  &__14 {
    font-size: 14px;
  }
}

.u-chevron {
  width: 10px;
  height: 10px;
  background-color: cv("white");
  mask-image: url(iv(chevron));
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}

.u-rotate {
  transform: rotate(0);

  &-180 {
    transform: rotate(180deg);
  }
}

.u-icon {
  &__confirm {
    &::before {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      vertical-align: middle;
      content: "";
      background-color: cv("success");
      mask-image: url(iv("confirm"));
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
    }
  }

  &__delete {
    position: absolute;
    top: 50%;
    left: -35px;
    width: 15px;
    height: 16px;
    content: "";
    background-color: cv("white");
    mask-image: url(iv("delete"));
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    cursor: pointer;
    transform: translateY(-50%);

    &-delivery {
      top: auto;
      bottom: 42px;
      transform: translateY(0);
    }
  }

  &__check {
    position: absolute;
    top: 50%;
    left: -12px;
    width: 10px;
    height: 8px;
    background-color: cv(success);
    mask-image: url(iv(check));
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    transform: translateY(-50%);
  }

  &__warning {
    position: absolute;
    top: 50%;
    left: -40px;
    z-index: 1;
    display: block;
    flex-grow: 0;
    width: 19px;
    min-width: 19px;
    height: 16px !important;
    padding: 0;
    cursor: pointer;
    transform: translateY(-50%);
  }
}

.u-image {
  &-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
