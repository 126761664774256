@use "../settings/index.scss" as *;

@use "@glsass/tools/tools.string" as _str;
@use "@glsass/tools/tools.global" as *;

.c-position-card {
  position: relative;
  padding: 10px;
  background: cv("white");
  border-radius: 10px;
  cursor: pointer;

  &__taken {
    border: 1px solid cv("danger");

    .c-position-card {
      &-content {
        background: #ffedef;
      }

      &-priority {
        color: cv("danger");
      }

      &-start {
        color: cv("danger");
      }

      &-check {
        background: rgba(cv("danger"), 0.7);

        &-checked {
          svg path {
            fill: cv("danger");
          }
        }
      }
    }
  }

  &-content {
    position: relative;
    display: flex;
    height: 155px;
    background: #fef5f6;
    border-radius: 10px;
  }

  &-picture {
    position: relative;
    width: 155px;
    height: 100%;
    overflow: hidden;
    background: cv("gray", 400);
    border-radius: 10px;
    border-top-right-radius: 0;

    &-type {
      position: absolute;
      top: 0;
      right: 0;
      padding: 4px 10px 0;
      color: cv("white");
      font-size: 10px;
      text-transform: uppercase;
      background: cv("black");
    }
  }

  &-priority {
    position: absolute;
    top: 42px;
    left: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: cv("primary");
    font-size: 14px;
    font-weight: bolder;
    background: cv("white");
    border-radius: 50%;
  }

  &-infos {
    flex-grow: 1;
    margin-left: 30px;
    padding-right: 20px;

    p {
      margin-bottom: 0;
    }
  }

  &-start {
    color: cv("primary");
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &-id {
    color: cv("gray", B00);
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &-pdv {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 5px 10px;
    background: cv("black");

    p {
      line-height: 1.1;
      text-align: right;
    }
  }

  &-check {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    background: rgba(cv("primary"), 0.7);
    border-radius: 15px;

    &-inner {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      overflow: hidden;
      background: cv("white");
      border-radius: 10px;
    }

    &-checked {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
}
