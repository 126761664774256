////
/// @group Layouts
/// Actions
////

@use "@glsass/tools/tools.global" as *;

.l-actions {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 70px;
  background-color: cv("black");

  &--modal {
    position: absolute;
    right: 20px;
    bottom: 20px;
    left: 20px;
    width: calc(100vw - 40px);
  }
}
