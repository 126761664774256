////
/// @group Modules
/// Table (Order Retails)
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.m-table {
  $_root-table: &;

  display: flex;
  width: 100%;
  max-height: 70vh;
  margin-bottom: 20px;
  overflow: auto;

  &.is-open {
    #{$_root-table}__details {
      display: block;
    }

    #{$_root-table}__footer,
    #{$_root-table}__line--sum {
      margin-top: 0;
    }
  }

  &__details {
    display: none;
  }

  &__sticky {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  &__fix {
    position: sticky;
    left: 0;
    z-index: 2;
    flex-shrink: 0;
    width: 525px;
    height: 100%;
    border-right: 2px solid cv("gray", E00);
  }

  &__title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 80px;
    padding: 20px;
    color: cv("white");
    font-size: 20px;
    text-transform: uppercase;
    background-image: linear-gradient(to right, cv("purple"), cv("primary"));

    &--empty {
      background-image: none;
    }
  }

  &__header {
    height: 30px;
    padding: 0 10px;
    color: cv("gray", B00);
    font-size: 10px;
    text-transform: uppercase;
    background-color: cv("black");
  }

  &__row {
    height: 40px;
    margin-bottom: 1px;
    padding: 0 10px;
    color: cv("black");
    font-size: 10px;
    background-color: cv("white");
  }

  &__footer {
    height: 30px;
    margin-top: 1px;
    padding: 0 10px;
    color: cv("white");
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    background-color: cv("black");
  }

  &__cell {
    display: flex;
    align-items: center;

    &--bdl {
      border-left: 1px solid cv("gray", E00);
    }
  }

  &__refs {
    display: flex;
    width: auto;
    height: 100%;
    // overflow-x: auto;
  }

  &__col {
    flex-shrink: 0;
    width: 200px;
    margin-right: 1px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__ref {
    display: flex;
    flex-direction: column;
    height: 110px;
    padding: 10px 20px;
    color: cv("white");
    font-size: 10px;
    background-color: cv("gray", 300);
  }

  &__line {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-bottom: 1px;
    color: cv("black");
    font-size: 12px;
    font-weight: 900;
    background-color: cv("white");

    &--sum {
      height: 30px;
      margin-top: 1px;
      margin-bottom: 20px;
      color: cv("white");
      background-color: cv("black");
    }
  }
}
