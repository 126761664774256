@font-face {
  font-family: "SFR";
  font-weight: 900;
  font-style: normal;
  src: url("../../fonts/SFR-Black.woff2") format("woff2");
  src: url("../../fonts/SFR-Black.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "SFR";
  font-weight: 900;
  font-style: italic;
  src: url("../../fonts/SFR-BlackItalic.woff2") format("woff2");
  src: url("../../fonts/SFR-BlackItalic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "SFR";
  font-weight: bold;
  font-style: italic;
  src: url("../../fonts/SFR-BoldItalic.woff2") format("woff2");
  src: url("../../fonts/SFR-BoldItalic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "SFR";
  font-weight: 300;
  font-style: normal;
  src: url("../../fonts/SFR-Light.woff2") format("woff2");
  src: url("../../fonts/SFR-Light.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "SFR";
  font-weight: 100;
  font-style: italic;
  src: url("../../fonts/SFR-ThinItalic.woff2") format("woff2");
  src: url("../../fonts/SFR-ThinItalic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "SFR";
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/SFR-Regular.woff2") format("woff2");
  src: url("../../fonts/SFR-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "SFR";
  font-weight: bold;
  font-style: normal;
  src: url("../../fonts/SFR-Bold.woff2") format("woff2");
  src: url("../../fonts/SFR-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "SFR";
  font-weight: 100;
  font-style: normal;
  src: url("../../fonts/SFR-Thin.woff2") format("woff2");
  src: url("../../fonts/SFR-Thin.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "SFR";
  font-weight: 300;
  font-style: italic;
  src: url("../../fonts/SFR-LightItalic.woff2") format("woff2");
  src: url("../../fonts/SFR-LightItalic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "SFR";
  font-weight: normal;
  font-style: italic;
  src: url("../../fonts/SFR-Italic.woff2") format("woff2");
  src: url("../../fonts/SFR-Italic.woff") format("woff");
  font-display: swap;
}
