////
/// @group Components
/// Changelog
////

@use "@glsass/tools/tools.global" as *;

@use "@glsass/tools/tools.color" as _color;

.c-changelog {
  padding: 7px 20px;
  color: cv("black");
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: cv("white");
  border: 1px solid cv("gray", B00);

  &:not(:last-child) {
    margin-right: 20px;
  }

  &--danger {
    color: cv("danger");
    background-color: _color.tint(cv("danger"), 12);
  }

  &--warning {
    color: cv("warning");
    background-color: _color.tint(cv("warning"), 12);
  }

  &--success {
    color: cv("success");
    background-color: _color.tint(cv("success"), 12);
  }
}
