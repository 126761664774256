////
/// @group Components
/// Card
////

@use "sass:math";

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-card {
  $_root-card: &;

  position: relative;
  display: block;
  padding: 10px 10px 10px;
  color: cv("black");
  background-color: cv("white");

  &--with-header,
  &.has-header {
    padding-top: 40px;
  }

  &__link {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: calc(100% - 30px);

    &:hover {
      ~ div #{$_root-card}__preview:where(a) {
        &::after {
          content: "";
        }
      }
    }
  }

  &__figure {
    z-index: 2;
  }

  // this classes is just to handle aspect-ratio and others sub-components behaviors
  &__file {
    .c-tags {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      pointer-events: none;
    }

    .c-download {
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 7;
    }
  }

  &__preview {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    border-radius: 20px;

    &:where(a):hover {
      &::after {
        content: "";
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(cv("black"), 0.25);
      background-image: url(iv(
        "search",
        $options: (currentColor: rgba(cv("white"), 0.6))
      ));
      background-repeat: no-repeat;
      background-position: center;
      background-size: 40px;
      cursor: pointer;
      transition: opacity 0.35s graphics.$ui-transition-timing-function;
    }

    &--video {
      &::after {
        background-image: url(iv(
          "play",
          $options: (currentColor: rgba(cv("white"), 0.6))
        ));
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding: 10px;
    font-size: 10px;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: math.div(18, 12);
  }

  hr,
  &__separator {
    margin: 20px 0;
  }

  &__leads {
    :last-child {
      margin-bottom: 0;
    }
  }

  &__lead {
    position: relative;
    display: grid;
    grid-template-columns: 80px 1fr;
    margin-bottom: 7px;
    padding-right: 15px;
    font-size: 10px;

    &.is-valid,
    &.is-invalid {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 10px;
        height: 10px;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        transform: translateY(-50%);
      }
    }

    &.is-valid {
      &::after {
        background-color: cv(success);
        mask-image: url(iv(check));
      }
    }

    &.is-invalid {
      &::after {
        background-color: cv(danger);
        mask-image: url(iv(close));
      }
    }
  }
}
