////
/// @group Components
/// Chartpie
////

@use "@glsass/tools/tools.global" as *;

.c-chartpie {
  canvas {
    width: 200px !important;
    height: 200px !important;
    border: 10px solid cv("gray", E00);
    border-radius: 50%;
  }

  &__legend {
    p {
      font-size: 14px;

      &::before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 15px;
        vertical-align: middle;
        background-color: cv("gray", D00);
        border-radius: 50%;
      }

      &:nth-child(1)::before {
        background-color: cv("primary");
      }

      &:nth-child(2)::before {
        background-color: cv("purple");
      }

      &:nth-child(3)::before {
        background-color: cv("team", maker);
      }

      &:nth-child(4)::before {
        background-color: cv("team", fab);
      }

      strong {
        font-weight: 900;
      }
    }
  }

  &__title {
    margin: 15px 0 20px 0;
    font-size: 20px;
    font-weight: 900;

    strong {
      font-size: 30px;
    }
  }

  &__titles {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: end;
    font-size: 20px;
    font-weight: 900;

    span {
      padding: 15px 50px 15px 20px;
      border-bottom: 1px solid cv("gray", D00);

      &:nth-child(odd) {
        padding: 15px 0 15px 50px;
        font-size: 30px;
        border-left: 1px solid cv("gray", D00);
      }

      &:nth-last-of-type(-n + 2) {
        border-bottom: 0;
      }
    }
  }
}
