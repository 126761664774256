////
/// @group Components
/// Validation
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-validation {
  $_root-validation: &;
  width: 100%;

  // Inside card
  .c-card & {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
  }

  // Inside sidebar
  .c-sidebar & {
    width: calc(100% + 60px);
    margin-left: -30px;

    #{$_root-validation}__title {
      display: grid;
      grid-template-columns: 140px 1fr 10px;
      height: 40px;
      padding: 10px 30px;
      font-size: 12px;
    }

    #{$_root-validation}__details {
      padding: 20px 30px;
    }
  }

  &.is-valid {
    #{$_root-validation}__title,
    #{$_root-validation}__details {
      background-color: cv("success");
    }
  }

  &.is-invalid {
    #{$_root-validation}__title,
    #{$_root-validation}__details {
      background-color: cv("danger");
    }

    #{$_root-validation}__heading {
      margin-bottom: 10px;
    }
  }

  &[open] {
    max-height: 100%;
    overflow: scroll;

    #{$_root-validation}__title::after {
      transform: rotate(-0.5turn);
    }
  }

  &__title {
    position: sticky;
    top: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    height: 30px;
    margin-bottom: 1px;
    padding: 10px;
    color: cv("white");
    font-size: 10px;
    text-transform: uppercase;
    background-color: cv("black");
    cursor: pointer;
    user-select: none;

    &::marker {
      display: none;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-left: auto;
      background-color: currentColor;
      mask-image: url(iv(chevron));
      transition: transform 0.35s graphics.$ui-transition-timing-function;
    }

    span {
      opacity: 0.8;
    }
  }

  &__details {
    padding: 20px;
    color: cv("white");
    font-size: 12px;
    line-height: 1.3;
    background-color: cv("black");

    p {
      margin-bottom: 10px;
    }

    > :last-child {
      margin-bottom: 0;
    }

    hr {
      color: cv("white");
      opacity: 0.3;
    }
  }

  &__heading {
    margin-bottom: 5px;
    font-size: 10px;
    text-transform: uppercase;
    opacity: 0.8;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: cv("white");
    line-height: 1;
    text-decoration: none;

    i {
      font-size: 10px;
    }

    &:hover {
      &::after {
        background-color: cv("gray", D00);
      }
    }

    &::after {
      content: "";
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      background-color: cv("white");
      background-image: url(iv(
        "download",
        $options: (currentColor: cv("danger"))
      ));
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px auto;
      border-radius: 50%;
      transition: background-color 0.35s graphics.$ui-transition-timing-function;
    }
  }
}
