////
/// @group Components
/// Button check
////

@use "sass:math";

@use "@glsass/graphics";

@use "@glsass/tools/tools.vhd" as vhd;
@use "@glsass/tools/tools.global" as *;

.c-button-check {
  display: inline-block;
  padding: 8px 20px;
  color: cv("black");
  font-weight: 700;
  line-height: 1;
  background-color: cv("white");
  border: 0;
  border-radius: graphics.$ui-border-radius * 2;
  cursor: pointer;
  transition: all 0.35s graphics.$ui-transition-timing-function;
  user-select: none;

  &:not(:disabled):not(.is-disabled):hover {
    color: cv("primary");
    background: cv("white");
  }

  &:focus,
  &:focus-within {
    outline: 0;
    box-shadow: 0 0 0 math.div(1rem, 4) rgba(cv("white"), 0.25);
  }

  &.is-active {
    color: cv("white");
    background-color: cv("primary");

    &:focus,
    &:focus-within {
      box-shadow: 0 0 0 math.div(1rem, 4) rgba(cv("primary"), 0.25);
    }
  }

  &.is-inactive {
    color: cv("white");
    background-color: cv("gray", 700);

    &:focus,
    &:focus-within {
      box-shadow: 0 0 0 math.div(1rem, 4) rgba(cv("gray", 700), 0.25);
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    @include vhd.visually-hidden();
  }
}
