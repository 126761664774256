////
/// @group Components
/// Breadcrumb
////

@use "@glsass/tools/tools.global" as *;

.c-breadcrumb {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  overflow-x: scroll;
  font-size: 12px;
  list-style: none;

  &__item {
    a {
      color: cv("gray", B00);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &:not(:last-of-type) {
      &::after {
        content: "/";
        padding: 0 4px;
      }
    }

    &.is-active {
      font-weight: 700;
    }
  }
}
