////
/// @group Layout
/// Modal
////

@use "@glsass/tools/tools.global" as *;

.l-modal {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-header {
    color: cv("black");
    background-color: cv("white");

    span {
      display: block;
      margin-bottom: 5px;
      color: cv("gray", "700");
      font-size: 10px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }

    h1 {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 700;

      &::before {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-position: top left;
        background-size: contain;
      }

      &.is-search {
        &::before {
          content: "";
          background-image: url(iv("search"));
        }
      }
    }
  }
}
