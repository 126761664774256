////
/// @group Components
/// Callout
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.color" as _color;
@use "@glsass/tools/tools.global" as *;

.c-callout {
  $root: &;

  margin-bottom: 1px;
  text-decoration: none;

  &[open] {
    #{$root}__title::after {
      transform: rotate(-0.5turn);
    }
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 40px;
    color: cv("white");
    list-style: none;
    background-color: cv("danger");
    cursor: pointer;
    user-select: none;

    &:focus {
      background-color: cv("danger", 600);
      outline: 0;
    }

    &::marker {
      display: none;
    }
  }

  &__title {
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      width: 16px;
      height: 16px;
      margin-right: 10px;
      background-color: cv("white");
      mask-image: url(iv("warning"));
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-left: auto;
      background-color: currentColor;
      mask-image: url(iv(chevron));
      transition: transform 0.35s graphics.$ui-transition-timing-function;
    }
  }

  &__body {
    box-sizing: border-box;
    color: cv("black");
  }

  &__line {
    display: flex;
    align-items: center;
    min-height: 40px;
    padding: 10px 0;
    background-color: _color.tint(cv("danger"), 10);
    border-top: 1px solid cv("gray", E00);
  }

  &__link {
    display: block;
    color: currentColor;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      &::after {
        transform: rotate(-0.25turn) translateY(5px);
      }
    }

    > * {
      flex-shrink: 0;
    }

    &::after {
      content: "";
      display: inline-block;
      flex-shrink: 0;
      width: 10px;
      height: 10px;
      margin-left: 10px;
      background-color: currentColor;
      mask-image: url(iv(chevron));
      transform: rotate(-0.25turn);
      transition: transform 0.35s graphics.$ui-transition-timing-function;
    }
  }

  &--no-body {
    display: block;

    #{$root}__title {
      &::after {
        width: 16px;
        height: 16px;
        transform: rotate(-0.25turn);
      }
    }
  }
}
