////
/// @group Components
/// Breadcrumb
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-upload {
  $root: &;

  position: relative;
  display: flex;
  background-color: cv("gray", 400);
  border: 1px solid cv("gray", 500);
  border-radius: graphics.$ui-border-radius;

  input[type="file"] {
    display: none;
  }

  &.is-dragging {
    background-color: cv("gray", 500);
  }

  &.is-invalid {
    border-color: cv("danger");
  }

  &.is-loading {
    #{$root}__container {
      padding-right: 60px;
      padding-left: 60px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% - 80px);
      min-width: 170px;
      max-width: 240px;
      height: calc(100% - 80px);
      min-height: 170px;
      max-height: 240px;
      background-color: cv("primary");
      mask-image: url(iv("loading-alt"));
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      transform: translate3d(-50%, -50%, 0);
      animation-name: loading-abs;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  &.has-file {
    background-color: cv("gray", 500);

    #{$root}__container {
      border-color: cv("gray", 500);
    }
  }

  &--standalone {
    border: 0;
  }

  &--refused {
    label {
      z-index: 1;
    }

    #{$root}__preview {
      opacity: 0.15;
    }
  }

  &--image {
    #{$root}__container {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    #{$root}__icon {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  // Must be the label
  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 20px);
    margin: 10px;
    padding: 15px 30px;
    border: 1px dashed cv("gray", B00);
    border-radius: 10px;
    cursor: pointer;
  }

  &__icon {
    margin-right: 30px;

    img {
      display: block;
    }

    &--add {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: -8px;
        right: -8px;
        display: block;
        width: 20px;
        height: 20px;
        background-color: cv("primary");
        background-image: url(iv("add", $options: (currentColor: cv("white"))));
        background-repeat: no-repeat;
        background-position: center;
        background-size: 10px auto;
        border-radius: 50%;
      }
    }
  }

  &__text {
    color: cv("white");

    span {
      color: cv("gray", B00);
      font-size: 12px;
    }
  }

  &__name {
    word-break: break-all;
  }

  &__remove {
    position: absolute;
    top: 0;
    right: calc(-2rem);
    width: 1rem;
    height: 1rem;
    background-image: url(iv("delete", $options: (currentColor: cv("white"))));
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    transition: opacity 0.35s graphics.$ui-transition-timing-function;

    &:hover {
      opacity: 0.7;
    }
  }

  &__actions {
    z-index: 1;
  }

  &__download {
    position: absolute;
    right: calc(-2rem);
    bottom: 0;
    color: cv("white");
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    transition: opacity 0.35s graphics.$ui-transition-timing-function;

    &:hover {
      opacity: 0.7;
    }

    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 50%;
      width: 2rem;
      height: calc(1rem + 20px);
      background-image: url(iv(
        "download-alt",
        $options: (currentColor: cv("white"))
      ));
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 16px;
      transform: translateX(-50%);
    }

    &:last-child:not(:first-child) {
      bottom: 50px;
    }
  }

  &__preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: graphics.$ui-border-radius;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: cv("gray", 400);
    }
  }

  &__details {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 50px;
    padding: 8px 20px;
    color: cv("white");
    background-color: rgba(cv("black"), 0.8);

    :first-child {
      margin-bottom: 2px;
    }
  }
}
