////
/// @group Components
/// Table
////

@use "sass:selector";

@use "@glsass/tools/tools.color" as _color;
@use "@glsass/tools/tools.global" as *;

.c-table {
  $_root-table: &;

  margin-bottom: 50px;
  box-shadow: 0 3px 6px rgba(cv("black"), 0.15);

  &__header {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 20px;
    background-color: cv("black");

    #{$_root-table}__tr {
      flex-grow: 1;
      height: 100%;
    }

    #{$_root-table}__th {
      display: inline-flex;
      align-items: center;
      color: cv("gray", B00);
      font-size: 12px;
      text-transform: uppercase;
      cursor: default;

      &.is-sortable {
        cursor: pointer;

        &::after {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 5px;
          vertical-align: middle;
          border-width: 4px 3px 0 3px;
          border-style: solid;
          border-color: currentColor transparent transparent transparent;
          opacity: 0;
          transition: transform 0.35s ease, opacity 0.35s ease;
        }
      }

      &.is-sorted {
        font-weight: bold;

        &::after {
          content: "";
          opacity: 1;
        }
      }

      &.is-sorted-desc {
        &::after {
          content: "";
          transform: rotate(-180deg);
        }
      }
    }

    &--small {
      > #{$_root-table}__tr #{$_root-table}__th {
        font-size: 9px;
      }
    }

    &--sticky {
      position: sticky;
      top: 0;
      z-index: 99;
    }
  }

  &__body {
    #{$_root-table}__tr {
      position: relative;
      align-items: center;
      margin-bottom: 1px;
      padding: 0 20px;
      color: cv("black");
      background-color: cv("white");
      transition: background-color 0.35s ease;

      & > a {
        align-items: center;
        color: cv("black");
        text-decoration: none;
      }

      &:hover {
        background-color: cv("gray", E00);
      }

      &#{$_root-table}__tr--h50 {
        #{$_root-table}__td {
          min-height: 50px;
        }
      }

      &#{$_root-table}__tr--h60 {
        #{$_root-table}__td {
          min-height: 60px;
        }
      }

      &#{$_root-table}__tr--small {
        #{$_root-table}__td {
          font-size: 10px;
        }
      }

      #{$_root-table}__td {
        display: inline-flex;
        align-items: center;
        min-height: 40px;
        padding-top: 10px;
        padding-bottom: 10px;

        &--border {
          padding-left: 20px;
          border-left: 1px solid cv("white");
        }
      }

      &--danger {
        background-color: _color.tint(cv("danger"), 12);
      }

      &--warning {
        background-color: _color.tint(cv("warning"), 12);
      }

      &--success {
        background-color: _color.tint(cv("success"), 12);
      }
    }
  }

  &__line {
    &--small {
      #{$_root-table}__td {
        font-size: 10px;
      }
    }

    &.is-open {
      position: relative;
      z-index: 1;
    }

    &:hover {
      position: relative;
      z-index: 2;
    }
  }

  &__sub {
    display: none;
    margin-bottom: 1px;

    .is-open & {
      display: block;
    }
  }

  &__img {
    display: block;
    width: 80px;
    height: 80px;
    object-fit: cover;
  }

  &__button {
    display: inline-flex;
    align-items: center;
    padding: 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    &.is-inspect {
      &:hover {
        &::after {
          background-color: cv("purple");
        }
      }

      &::after {
        content: "";
        width: 16px;
        height: 16px;
        margin-left: 10px;
        background-color: cv("gray", 700);
        mask-image: url(iv("search"));
        mask-repeat: no-repeat;
        mask-position: center;
        transition: background-color 0.35s ease;
      }
    }

    &.is-arrow {
      &::after {
        content: "";
        width: 12px;
        height: 10px;
        margin-left: 10px;
        background-color: currentColor;
        mask-image: url(iv("arrow-right"));
        mask-repeat: no-repeat;
        mask-position: center;
      }
    }

    &.is-download {
      width: 40px;
      height: 40px;
      background-color: cv("gray", 700);

      &::after {
        content: "";
        width: 100%;
        height: 20px;
        background-color: cv("white");
        mask-image: url(iv("download-alt"));
        mask-repeat: no-repeat;
        mask-position: center;
      }
    }
  }

  &__outside {
    position: absolute;
    top: 50%;

    &:hover {
      z-index: 1;
    }

    &--left {
      left: 0;
      transform: translate(calc(-100% - 20px), -50%);
    }

    &--right {
      right: 0;
      transform: translate(calc(100% + 20px), -50%);
    }
  }
}
