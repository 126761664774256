////
/// @group Components
/// Select filter
////

@use "@glsass/tools/tools.global" as *;

@use "@glsass/graphics";

.c-select-filter {
  position: relative;
  padding: 0 20px;

  &:not(:last-child) {
    border-right: 1px solid cv("gray", D00);
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    width: 10px;
    height: 10px;
    background-color: cv("gray", 700);
    mask-image: url(iv("chevron"));
    mask-repeat: no-repeat;
    mask-position: right center;
    transform: translate(0, -50%);
    pointer-events: none;
  }

  &__label {
    padding-right: 2px;
    color: #707070;
    font-size: 14px;
  }

  &__select {
    padding-right: 20px;
    color: cv("purple");
    font-size: 14px;
    font-weight: 700;
    border: 0;
    -webkit-appearance: none;
  }
}
