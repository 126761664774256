@use "settings.icons" as icons;

// SETTINGS
@forward "@glsass/settings" with (
  // Font
  $g-font-size-base: 0.875rem,
  $g-font-line-height-base: 1.2,
  // Spacers
  $g-spacers: (
    d: 5px, // demi
    1: 10px,
    2: 20px,
    3: 30px,
    4: 40px,
    5: 50px,
    6: 60px,
    7: 70px,
    8: 80px,
    9: 90px,
  ),
  // Grid
  $g-grid-breakpoints: (
    md: 0, // a.k.a 1280px
    lg: 1440px,
    xl: 1600px,
  ),
  $g-grid-container-max-widths: (
    md: 1116px,
    lg: 1256px,
    xl: 1426px,
  ),
  $g-grid-gutter-width: 1rem,
  // Colors
  $g-color-primary: #c40479,
  $g-color-secondary: #2c297d,
  $g-color-danger: #e2001a,
  $g-color-success: #5ec351,
  $g-color-warning: #ff9d00,
  $g-color-gray-100: #111,
  $g-color-gray-200: #222,
  $g-color-gray-300: #333,
  $g-color-gray-400: #444,
  $g-color-gray-500: #555,
  $g-color-gray-600: #666,
  $g-color-gray-700: #777,
  $g-color-gray-800: #888,
  $g-color-gray-900: #999,
  $g-color-grays: (
    "100": #111,
    "200": #222,
    "300": #333,
    "400": #444,
    "500": #555,
    "600": #666,
    "700": #777,
    "800": #888,
    "900": #999,
    "A00": #aaa,
    "B00": #bbb,
    "C00": #ccc,
    "D00": #ddd,
    "E00": #eee,
  ),
  $g-colors-database: (
    "purple": (
      "base": #7c297d,
    ),
    "kit": (
      "base": #ffcd00,
    ),
    "team": (
      "comm": #d15a9c,
      "crea": #c40479,
      "fab": #23b5b7,
      "log": #7c297d,
      "maker": #139bd6,
      "video": #000,
    )
  ),
  $g-icons-database: icons.$g-sfr-icons-file,
);

// TOOLS
@use "@glsass/tools/tools.global" as *;

// GRAPHICS
@forward "@glsass/graphics" with (
  // Global
  $ui-border-radius: 10px,
  // Fonts
  $ui-fonts-family-map: "SFR",
  // Form
  $ui-form-border-color: cv("gray", 500),
  $ui-form-background-color: cv("gray", 400),
  $ui-form-text-color: cv("white"),
  $ui-form-placeholder-color: cv("gray", A00),
  $ui-form-focus-border-color: cv("gray", 500),
  // $ui-form-focus-shadow-width: 2px,
  $ui-form-focus-shadow-color: rgba(cv("gray", D00), 0.5),
  $ui-form-states: (
    "invalid": (
      "color": cv("danger"),
      "background": rgba(cv(danger), 0.1),
    )
  ),
);

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

@keyframes loading-abs {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotate(1turn);
  }
}
