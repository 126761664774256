////
/// @group Components
/// WYSIWYG
////

@use "@glsass/tools/tools.global" as *;

.c-wysiwyg {
  color: cv("black");

  h1 {
    margin: 50px 0 30px 0;
    padding: 0 0 10px 0;
    font-size: 22px;
    font-weight: 900;
    text-transform: uppercase;
    border-bottom: 2px solid;

    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    margin: 30px 0 25px 0;
    font-size: 18px;
    font-weight: 700;
  }

  h3 {
    margin: 30px 0 20px 0;
    font-size: 16px;
    font-weight: 700;
  }

  p {
    max-width: 66.66%;
    margin: 0 0 15px 0;
    font-size: 14px;
  }

  a {
    color: cv("primary");

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    padding-left: vr(1.5);
  }

  * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
