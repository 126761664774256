@use "./assets/sass/sfr" as sfr;





















@use '@glsass/tools/tools.global' as *;

.m-env {
  position: fixed;
  top: 50%;
  padding: 10px 0;
  color: cv('white');
  font-size: 40px;
  text-transform: uppercase;
  background-color: cv('danger');
  transform: translateY(-50%) rotate(-0.5turn);
  writing-mode: vertical-lr;
}
