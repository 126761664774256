////
/// @group Components
/// Accordion
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-accordion {
  $root: &;

  .c-accordion__header {
    position: relative;
    list-style: none;
    cursor: pointer;

    &::-webkit-details-marker {
      display: none;
    }
  }

  &__chevron {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 20px;
    background-color: cv("gray", 700);
    mask-image: url(iv(chevron));
    mask-size: contain;
    transition: color 0.35s graphics.$ui-transition-timing-function,
      transform 0.35s graphics.$ui-transition-timing-function;
  }

  &__status {
    position: absolute;
    top: 50%;
    left: -40px;
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid;
    border-radius: 50%;
    transform: translate(0, -50%);

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 1px;
      background-color: currentColor;
      transform: translate(-50%, -50%);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
      transition: transform 0.35s ease;
    }
  }

  &[open] {
    #{$root}__chevron {
      background-color: cv("gray", 300);
      transform: rotate(-180deg);
    }
    #{$root}__status {
      &::after {
        transform: translate(-50%, -50%) rotate(0);
      }
    }
  }
}
