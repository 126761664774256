////
/// @group Components
/// Progress
////

@use "@glsass/tools/tools.global" as *;

@use "@glsass/graphics";

.c-progress {
  $root-progress: &;

  --progress: 0;
  counter-reset: progress var(--progress);

  /* stylelint-disable-next-line */
  display: block;
  width: 100%;
  height: 10px;
  padding: 1px;
  background-color: cv("gray", B00);
  border-radius: 20px;

  &.is-complete {
    --progress: 100 !important;

    #{$root-progress}__bar {
      background: linear-gradient(to right, #208115, cv("success"));
    }
  }

  &__bar {
    position: relative;
    display: block;
    width: calc(var(--progress) * 1%);
    height: 100%;
    font-size: 10px;
    background: linear-gradient(to right, cv("purple"), cv("danger"));
    border-radius: 4px;

    &::before {
      content: counter(progress) "%";
      position: absolute;
      top: calc(100% + 3px);
      right: 0;
      display: block;
      font-weight: bold;
      transform: translate(50%, 0);
    }
  }
}
