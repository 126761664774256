////
/// @group Components
/// Button status
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-button-status {
  $_root-button: &;

  display: inline-flex;
  flex-shrink: 0;
  align-items: normal;
  height: 40px;
  padding: 0;
  color: cv("black");
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  border: 0;
  border-radius: graphics.$ui-border-radius;
  transition: all 0.35s graphics.$ui-transition-timing-function;
  user-select: none;

  &:not(:disabled):not(.is-disabled):hover {
    color: cv("primary");
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    color: cv("primary");
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(cv("white"), 0.25);
  }

  &:disabled,
  &.is-disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &__text,
  &__icon {
    background-color: cv("white");
    border: 0;
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    border-top-left-radius: graphics.$ui-border-radius;
    border-bottom-left-radius: graphics.$ui-border-radius;
  }

  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-left: 1px;
    background-color: cv("white");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    border-top-right-radius: graphics.$ui-border-radius;
    border-bottom-right-radius: graphics.$ui-border-radius;

    &::after {
      content: "";
      width: 16px;
      height: 16px;
      background-color: transparent;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      transition: all 0.35s graphics.$ui-transition-timing-function;
    }
  }

  &--deny {
    #{$_root-button}__icon::after {
      background: cv("danger");
      mask-image: url(iv("close"));
    }
  }

  &--accept {
    #{$_root-button}__icon::after {
      background-color: cv("success");
      mask-image: url(iv("check"));
    }
  }

  &.is-loading {
    #{$_root-button}__icon::after {
      background-color: cv("black");
      mask-image: url(iv("loading"));
      animation-name: loading;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  &.is-inactive {
    #{$_root-button}__text,
    #{$_root-button}__icon {
      color: cv("gray", 700);
      background-color: cv("gray", 300);
    }

    #{$_root-button}__icon::after {
      background-color: cv("gray", 700);
    }
  }

  &.is-active {
    &#{$_root-button}--accept {
      #{$_root-button}__text,
      #{$_root-button}__icon {
        color: cv("white");
        background-color: cv("success");
      }

      #{$_root-button}__icon::after {
        background-color: cv("white");
      }
    }

    &#{$_root-button}--deny {
      #{$_root-button}__text,
      #{$_root-button}__icon {
        color: cv("white");
        background-color: cv("danger");
      }

      #{$_root-button}__icon::after {
        background-color: cv("white");
      }
    }
  }
}
