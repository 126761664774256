////
/// @group Components
/// Chip
////

@use "@glsass/tools/tools.global" as *;

.c-chip {
  display: inline-flex;
  align-items: center;
  min-width: 1rem;
  height: 1rem;
  padding: 0 5px;
  color: cv("white");
  background-color: cv("black");
  border-radius: 9px;

  &--success {
    background-color: cv("success");
  }

  &--danger {
    background-color: cv("danger");
  }

  &--warning {
    background-color: cv("warning");
  }
}
