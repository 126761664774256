////
/// @group Components
/// Board
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-board {
  &__header {
    margin-bottom: 1px;
    padding: 0 20px;
    background-color: cv("gray", E00);
  }

  &__col {
    display: inline-flex;
    align-items: center;
    height: 50px;

    &--border {
      border-left: 1px solid cv("white");
    }
  }

  &__link {
    display: block;
    height: 50px;
    padding: 0 20px;
    color: inherit;
    text-decoration: none;
    transition: background-color 0.35s graphics.$ui-transition-timing-function;

    &:hover {
      background: cv("gray", E00);
    }

    &:last-child {
      margin-bottom: 1px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid cv("gray", E00);
    }
  }
}
