////
/// @group Components
/// Logo
////

.c-logo {
  display: block;
  width: 5.5rem;
  height: 2.5rem;
  object-fit: contain;

  &--login {
    position: absolute;
    top: 50px;
    left: 90px;
    width: 8.925rem;
    height: 3.75rem;
  }
}
