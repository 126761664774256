////
/// @group Components
/// Anchor
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-anchor {
  $root: &;

  position: relative;
  scroll-margin-block-start: 10px;
  scroll-behavior: smooth;
  font-size: 20px !important;
  font-weight: 400 !important;

  &:target {
    a[href] {
      color: cv("primary");
      font-weight: 700;

      &::before {
        opacity: 1;
        transform: translate(calc(-100% - 10px), -50%);
      }
    }
  }

  a[href] {
    color: cv("black");
    text-decoration: none;

    &:hover {
      text-decoration: underline;

      &::before {
        opacity: 1;
        transform: translate(calc(-100% - 10px), -50%);
      }
    }

    &::before {
      content: "#";
      position: absolute;
      top: 50%;
      left: 0;
      opacity: 0;
      transform: translate(calc(-100%), -50%);
      transition: opacity 0.35s graphics.$ui-transition-timing-function,
        transform 0.35s graphics.$ui-transition-timing-function;
    }
  }
}
