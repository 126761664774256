////
/// @group Components
/// Alert
////

@use "sass:math";

@use "@glsass/graphics";

@use "@glsass/tools/tools.color" as _color;
@use "@glsass/tools/tools.global" as *;

.c-alert {
  $root: &;

  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  min-height: 40px;
  margin-bottom: 1px;
  padding: 12px 0;
  color: cv("black");
  line-height: math.div(16, 14);

  &::before {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
  }

  &::after {
    position: absolute;
    top: 0;
    left: calc(-50vw + 50%);
    z-index: -1;
    display: block;
    width: 100vw;
    height: 100%;
  }

  &--success {
    &::before {
      content: "";
      background-color: cv("success");
      mask-image: url(iv("success"));
    }

    &::after {
      content: "";
      background-color: _color.tint(cv("success"), 10);
    }
  }

  &--danger,
  &--error {
    &::before {
      content: "";
      background-color: cv("danger");
      mask-image: url(iv("warning"));
    }

    &::after {
      content: "";
      background-color: _color.tint(cv("danger"), 10);
    }
  }

  &__link {
    display: flex;
    align-items: center;
    color: currentColor;
    text-decoration: none;

    &:hover {
      &::after {
        transform: rotate(-0.25turn) translateY(5px);
      }
    }

    &::after {
      content: "";
      display: inline-block;
      flex-shrink: 0;
      width: 10px;
      height: 10px;
      margin-left: 10px;
      background-color: currentColor;
      mask-image: url(iv(chevron));
      transform: rotate(-0.25turn);
      transition: transform 0.35s graphics.$ui-transition-timing-function;
    }
  }
}
