////
/// @group Modules
/// Modal Vue
////

@use "@glsass/tools/tools.global" as *;

:root {
  --modal-width: 660px;
  --modal-height: 100vh;
  --modal-top: 70px;
}

.m-modal {
  top: var(--modal-top) !important;
  left: auto !important;
  width: 100% !important;
  height: calc(100vh - 70px) !important;
  height: calc(var(--modal-height) - var(--modal-top, 70px)) !important;
  overflow-y: auto !important;
  background-color: transparent !important;
  box-shadow: none !important;
  -webkit-overflow-scrolling: touch;

  @include mq {
    width: var(--modal-width) !important;
    margin-left: auto;
  }
}
