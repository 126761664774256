////
/// @group Components
/// Breadcrumb
////

@use "@glsass/tools/tools.global" as *;

$icons: "check", "close", "bell", "mail";

.c-icon {
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;

  @each $icon in $icons {
    &--#{$icon} {
      mask-image: url(iv($icon));
    }
  }
}
