////
/// @group Components
/// Reference
////

@use "sass:list";
@use "sass:math";

@use "@glsass/graphics";

@use "@glsass/tools/tools.color" as _color;
@use "@glsass/tools/tools.global" as *;

.c-reference, // alias name
.c-ref {
  $_root-ref: &;

  position: relative;
  background-color: cv("white");
  box-shadow: 0 3px 6px rgba(cv("black"), 0.15);

  &.is-open {
    @each $ref in $_root-ref {
      #{$ref}__body {
        display: block;
      }
    }
  }

  &.is-loading {
    pointer-events: none;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: rgba(cv("black"), 0.1);
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
      width: 180px;
      height: 180px;
      background-color: cv("primary");
      mask-image: url(iv("loading-alt"));
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      transform: translate3d(-50%, -50%, 0);
      animation-name: loading-abs;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  &__header,
  &__body {
    padding: 10px;
  }

  &__body {
    display: none;

    .is-open & {
      display: block;
    }
  }

  &__image {
    margin-bottom: 0;

    img {
      background-color: cv("gray", B00);
      border-radius: graphics.$ui-border-radius * 2;
      object-fit: contain;
    }
  }

  &__data {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
  }

  &__details {
    display: flex;
    color: cv("black");

    &::after {
      content: "";
      display: block;
      flex: 0 0 1px;
      order: 2;
      width: 1px;
      background-color: cv("gray", E00);
    }

    > div {
      flex: 1 0 clamp(250px, 45%, 50%);

      &:first-of-type {
        flex: 1 0 clamp(250px, 50%, 55%);
        order: 1;
        padding-right: 20px;
      }

      &:last-of-type {
        order: 3;
        padding-left: 20px;
      }
    }
  }

  &__id {
    margin-bottom: 10px;
    font-weight: 900;
  }

  &__name,
  &__format {
    display: -webkit-box;
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__name {
    -webkit-line-clamp: 3;
  }

  &__format {
    -webkit-line-clamp: 2;
    font-size: 12rem;
    font-weight: 700;
  }

  &__date {
    color: cv("primary");
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__positions {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: cv("gray", 400);
    font-size: 12px;
    background-color: cv("gray", E00);

    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background-image: url(iv("position"));
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  &__status {
    min-height: 120px;
    margin-bottom: 10px;
    padding: 20px;
    color: cv("primary");
    font-weight: 900;
    background-color: cv("gray", E00);

    span {
      display: block;
      margin-bottom: 5px;
      color: cv("gray", 700);
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
    }

    &--success {
      color: cv("success");
      background-color: _color.tint(cv("success"), 10);
    }

    &--warning {
      color: cv("warning");
      background-color: _color.tint(cv("warning"), 10);
    }
  }

  &__infos {
    background-color: cv("gray", 300);
  }

  &__more {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 20px;
    color: cv("white");
    font-weight: 700;
    text-transform: uppercase;
  }

  &__stats {
    flex: 1;
    padding: 10px 20px;
    color: cv("white");
    font-weight: 700;
    text-align: right;
    background-color: cv("black");

    span {
      display: block;
      margin-bottom: 5px;
      color: cv("gray", B00);
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
    }

    &:first-child {
      text-align: left;
      border-left: 1px solid cv("gray", 300);
    }
  }

  &__kitted {
    margin-bottom: 1px;
    padding: 5px;
    color: cv("black");
    font-size: 12px;
    background-color: cv("white");

    &-thumbnail {
      width: 70px;
      height: 70px;
      border-radius: 10px;
    }
  }

  &__head {
    height: 40px;
    padding: 0 20px;
    color: cv("white");
    font-size: 12px;
    text-transform: uppercase;
    background-image: linear-gradient(to right, cv("purple"), cv("primary"));
  }

  &__row {
    flex-grow: 1;
    height: 100%;
  }

  &__col {
    display: inline-flex;
    align-items: center;

    &:last-of-type {
      justify-content: flex-end;
    }
  }

  &__line {
    position: relative;
    height: 40px;
    padding: 0 20px;
    color: cv("black");
    font-size: 12px;
    background-color: cv("white");

    &:not(:last-child) {
      border-bottom: 1px solid cv("gray", E00);
    }

    &.is-valid {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 10px;
        height: 10px;
        background-color: cv(success);
        mask-image: url(iv(check));
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        transform: translateY(-50%);
      }
    }
  }

  &__warning {
    $root-warning: &;

    position: absolute;
    z-index: 1;
    width: 30px;
    height: 30px;
    color: cv("white");
    background-color: cv("danger");
    background-image: url(iv(
      "warning",
      $options: ("currentColor": cv("white"))
    ));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px auto;

    &:hover {
      width: 25%;
      height: 100%;
      padding: 20px;
      overflow-y: scroll;
      background-image: none;

      @each $ref in $root-warning {
        #{$ref}-content {
          display: block;
        }
      }
    }

    &-content {
      display: none;
    }
  }

  &__actions {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    transform: translate(calc(100% + 20px), -50%);
  }
}
