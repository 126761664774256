////
/// @group Components
/// Target table
////
///
@use "../settings";

@use "@glsass/tools/tools.global" as *;

.c-target-table {
  $root-warning: &;
  color: cv("white");

  &__hidden {
    width: 40px;
    min-width: inherit !important;
    background: cv("gray", "200");
  }

  &__btn {
    width: 40px;
    min-width: inherit !important;
    height: 40px !important;
    background: cv("gray", "B00");
    cursor: pointer;

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &::after {
      display: block;
      height: 16px;
      background-color: cv("gray", "300");
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      content: "";
    }

    &-edit {
      &::after {
        width: 16px;
        mask-image: url(iv(edit));
      }
    }

    &-delete {
      &::after {
        width: 15px;
        mask-image: url(iv(delete));
      }
    }
  }

  &__switch {
    &-disabled {
      background: cv("gray", "700");
    }
  }

  &__row {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    background: cv("gray", "300");
    border-bottom: 1px solid cv("gray", "200");

    & > div {
      display: flex;
      align-items: center;
      min-width: 80px;
      height: 100%;
      padding: 0 10px;
      border-right: 1px solid cv("gray", "200");

      &:first-of-type {
        flex-grow: 1;
        padding: 0 20px;
      }

      &.c-target-table__warning {
        position: absolute;
        top: 50%;
        left: -40px;
        z-index: 1;
        display: block;
        flex-grow: 0;
        width: 19px;
        min-width: 19px;
        height: 16px !important;
        padding: 0;
        cursor: pointer;
        transform: translateY(-50%);
      }
    }

    #{$root-warning}__areas {
      width: 440px;
      padding: 12px 20px;
    }

    #{$root-warning}__provider {
      width: 180px;
      padding: 12px 20px;
    }

    &:first-child {
      height: 30px;
      font-size: 12px;
      text-transform: uppercase;
      background: cv("black");
    }

    &:last-child {
      font-weight: bolder;
      text-transform: uppercase;
      background: cv("gray", "500");

      & > div:first-of-type {
        font-weight: 700;
      }
    }

    &-video {
      display: table;
      width: 100%;
      height: auto;

      & > div {
        display: table-cell;

        &:first-of-type {
          flex-grow: 1;
          width: calc(100% - 450px - 80px - 140px);
          padding: 12px 20px;
        }
      }

      #{$root-warning}__btn {
        position: relative;
        background: cv("gray", "200");

        &::after {
          position: absolute;
          top: 11px;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 40px;
          height: 40px;
          background: cv("gray", "B00");
          border-right: 1px solid cv("gray", "200");
        }
      }

      &:last-child {
        font-weight: 400;
        text-transform: none;
        background: cv("gray", "300");

        & > div:first-of-type {
          font-weight: 700;
        }
      }
    }
  }
}
