@use "sass:map";
@use "sass:math";

@use "@glsass/settings";
@use "@glsass/graphics";

@use "@glsass/tools/tools.generators" as generators;
@use "@glsass/tools/tools.global" as *;

@forward "@glsass/utilities/utilities.columns";
@forward "utilities.columns-custom";

@forward "@glsass/utilities/utilities.borders"
  with(
    $u-borders: (
      border-radius: (
        property: border-radius,
        class: br,
        values: (
          null: graphics.$ui-border-radius,
          d: math.div(graphics.$ui-border-radius, 2),
          2: graphics.$ui-border-radius * 2,
          pill: 50rem,
          full: 50%,
          0: 0,
        ),
      ),
    )
  );
@forward "@glsass/utilities/utilities.displays";
@forward "@glsass/utilities/utilities.flex";
@forward "@glsass/utilities/utilities.texts" with (
  $u-texts: (
    "font-weight": (
      "property": "font-weight",
      "class": "fw",
      "values": (
        "l": "lighter",
        "b": "bolder",
        "300": 300,
        "400": 400,
        "700": 700,
        "900": 900,
      ),
    ),
    "line-height": (
      "property": "line-height",
      "class": "lh",
      "values": (
        1: 1,
        "small": 0.75,
        // 0.5vr
        "base": 1.5,
        // 1vr - default line-height
        "large": 2.25,
        // 1.5vr
      ),
    ),
  )
);
@forward "@glsass/utilities/utilities.spacings" with (
  $u-spacings-negative-margins: true
);
@forward "@glsass/utilities/utilities.sizings" with (
  $u-sizings: (
    width: (
      property: width,
      class: w,
      values: (
        auto: auto,
        1: 1px,
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
      ),
    ),
  )
);
@forward "@glsass/utilities/utilities.positions";
@forward "@glsass/utilities/utilities.colors" with (
  $u-colors: (
    "color": (
      "property": "color",
      "class": "c",
      "values":
        map.merge(
          settings.$g-color-theme-colors,
          (
            "text": cv("text"),
            "muted": cv("text", "muted"),
            "reset": inherit,
            "gray-100": cv("gray", 100),
            "gray-200": cv("gray", 200),
            "gray-300": cv("gray", 300),
            "gray-400": cv("gray", 400),
            "gray-500": cv("gray", 500),
            "gray-600": cv("gray", 600),
            "gray-700": cv("gray", 700),
            "gray-800": cv("gray", 800),
            "gray-900": cv("gray", 900),
            "gray-A00": cv("gray", A00),
            "gray-B00": cv("gray", B00),
            "gray-C00": cv("gray", C00),
            "gray-D00": cv("gray", D00),
            "gray-E00": cv("gray", E00),
            "kit": cv("kit"),
          )
        ),
    ),
    "background-color": (
      "property": "background-color",
      "class": "bg",
      "values":
        map.merge(
          settings.$g-color-theme-colors,
          (
            "gray-100": cv("gray", 100),
            "gray-200": cv("gray", 200),
            "gray-300": cv("gray", 300),
            "gray-400": cv("gray", 400),
            "gray-500": cv("gray", 500),
            "gray-600": cv("gray", 600),
            "gray-700": cv("gray", 700),
            "gray-800": cv("gray", 800),
            "gray-900": cv("gray", 900),
            "gray-A00": cv("gray", A00),
            "gray-B00": cv("gray", B00),
            "gray-C00": cv("gray", C00),
            "gray-D00": cv("gray", D00),
            "gray-E00": cv("gray", E00),
            "transparent": transparent,
            "purple": cv("purple"),
            "kit": cv("kit"),
            "comm": cv("team", "comm"),
            "crea": cv("team", "crea"),
            "fab": cv("team", "fab"),
            "log": cv("team", "log"),
            "maker": cv("team", "maker"),
            "video": cv("team", "video"),
          )
        ),
    ),
  )
);

@forward "utilities.gap";
@forward "utilities.sizes";
@forward "utils";

@include generators.utilities(
  (
    "border-left": (
      property: border-left,
      class: bd,
      values: (
        l: 1px solid cv("gray", E00),
        lb: 1px solid cv("black"),
      ),
    ),
    "border-bottom": (
      property: border-bottom,
      class: bd,
      values: (
        b: 1px solid cv("gray", E00),
      ),
    ),
    "border-top-left-radius": (
      property: border-top-left-radius,
      class: "br-tl",
      values: (
        null: graphics.$ui-border-radius,
        d: math.div(graphics.$ui-border-radius, 2),
        2: graphics.$ui-border-radius * 2,
        0: 0,
      ),
    ),
    "border-top-right-radius": (
      property: border-top-right-radius,
      class: "br-tr",
      values: (
        null: graphics.$ui-border-radius,
        d: math.div(graphics.$ui-border-radius, 2),
        2: graphics.$ui-border-radius * 2,
        0: 0,
      ),
    ),
    "border-bottom-right-radius": (
      property: border-bottom-right-radius,
      class: "br-br",
      values: (
        null: graphics.$ui-border-radius,
        d: math.div(graphics.$ui-border-radius, 2),
        2: graphics.$ui-border-radius * 2,
        0: 0,
      ),
    ),
    "border-bottom-left-radius": (
      property: border-bottom-left-radius,
      class: "br-bl",
      values: (
        null: graphics.$ui-border-radius,
        d: math.div(graphics.$ui-border-radius, 2),
        2: graphics.$ui-border-radius * 2,
        0: 0,
      ),
    ),
  )
);

// Custom utilities
.u-d-f-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.u-d-f-aic {
  display: flex;
  align-items: center;
}

.u-d-f-jcc {
  display: flex;
  justify-content: center;
}

.u-fx-shrink {
  flex: 0 1 auto;
}

.u-mt-1p {
  margin-top: 1px;
}

.u-mr-1p {
  margin-right: 1px;
}

.u-mb-1p {
  margin-bottom: 1px;
}

.u-ml-1p {
  margin-left: 1px;
}

@each $index in (1, 2, 3) {
  .u-lc-#{$index} {
    display: -webkit-box;
    -webkit-line-clamp: #{$index};
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.u-bs-0 {
  box-shadow: none;
}

.u-bs-15 {
  box-shadow: 0 3px 6px rgba(cv("black"), 0.15);
}

.u-td-lt {
  text-decoration: line-through;
}

.u-cur-p {
  cursor: pointer;
}

.u-cur-d {
  cursor: default;
}

.u-cur-h {
  cursor: help;
}

.u-cur-zi {
  cursor: zoom-in;
}

// Hard color reset for link
.u-c-reset {
  a {
    color: inherit;
  }
}

.u-obj-cv {
  object-fit: cover;
}

.u-obj-cn {
  object-fit: contain;
}
