////
/// @group Components
/// Filters toggle
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-searchbar {
  $root: &;

  $_main-color: cv("gray", 700);

  .t-dark {
    $_main-color: cv("gray", B00);
  }

  /* stylelint-disable-next-line order/order */
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 4px;
  border-bottom: 1px solid $_main-color;
  transition: border 0.35s graphics.$ui-transition-timing-function;

  &:focus-within {
    border-color: cv("primary");

    #{$root}__icon {
      background-color: cv("primary");
    }
  }

  &__icon {
    content: "";
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-left: 10px;
    background-color: $_main-color;
    mask-image: url(iv(search));
    mask-repeat: no-repeat;
    mask-position: center;
    transition: background-color 0.35s graphics.$ui-transition-timing-function;
    pointer-events: none;

    #{$root}.is-loading & {
      mask-image: url(iv("loading"));
      animation-name: loading;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  &__input {
    width: 100%;
    padding: 5px 0;
    color: cv("black");
    font-size: 14px;
    background-color: transparent;
    border: 0;

    .t-dark & {
      color: cv("white");
    }

    &::placeholder {
      color: $_main-color;
    }

    :not(.is-loading) > & {
      &:not(:placeholder-shown) {
        & + #{$root}__icon {
          height: 10px;
          mask-image: url(iv(close));
          cursor: pointer;
          pointer-events: auto;
        }
      }
    }

    &:focus {
      outline: 0;
    }
  }

  &__separator {
    display: block;
    width: 1px;
    height: 1rem;
    margin: 0 20px;
    background-color: $_main-color;
  }
}
