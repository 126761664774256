////
/// @group Components
/// Subtable
////

@use "@glsass/tools/tools.global" as *;

.c-subtable {
  $_root-subtable: &;

  box-shadow: 0 3px 6px rgba(cv("black"), 0.15);

  &__header {
    position: sticky;
    top: 30px;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 10px;
    background-color: cv("gray", D00);

    #{$_root-subtable}__tr {
      flex-grow: 1;
      height: 100%;
    }

    #{$_root-subtable}__th {
      text-transform: uppercase;
    }
  }

  &__body {
    #{$_root-subtable}__tr {
      position: relative;
      align-items: center;
      padding: 0 10px;
      border-bottom: 1px solid cv("gray", E00);

      &.is-current {
        position: sticky;
        top: 60px;
        z-index: 1;
        background-color: cv("gray", D00);
      }

      & > a {
        text-decoration: none;
      }

      &:hover {
        &:has(a) {
          background-color: cv("gray", E00);
        }
      }

      &#{$_root-subtable}__tr--h50 {
        #{$_root-subtable}__td {
          min-height: 50px;
        }
      }

      &#{$_root-subtable}__tr--h60 {
        #{$_root-subtable}__td {
          min-height: 60px;
        }
      }
    }

    #{$_root-subtable}__td {
      min-height: 40px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &__th,
  &__td {
    display: inline-flex;
    align-items: center;
    color: cv("black");
    font-size: 10px;

    &--bdl {
      justify-content: flex-end;
      border-left: 1px solid cv("gray", E00);
    }
  }

  &__outside {
    position: absolute;
    top: 50%;

    &:hover {
      z-index: 1;
    }

    &--left {
      left: 0;
      transform: translate(calc(-100% - 20px), -50%);
    }

    &--right {
      right: 0;
      transform: translate(calc(100% + 20px), -50%);
    }
  }
}
