////
/// @group Modules
/// Line
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.color" as _color;
@use "@glsass/tools/tools.global" as *;

.m-line {
  $_root-line: &;

  position: relative;
  margin-bottom: 10px;

  &__title {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 10px;
    color: cv("white");
    font-size: 12px;
    text-transform: uppercase;
    background-image: linear-gradient(
      to right,
      _color.shade(cv("success"), 6),
      cv("success")
    );
    cursor: pointer;
    user-select: none;

    &--warning {
      background-image: linear-gradient(
        to right,
        _color.shade(cv("warning"), 6),
        cv("warning")
      );
    }

    &--danger {
      background-image: linear-gradient(
        to right,
        _color.shade(cv("danger"), 6),
        cv("danger")
      );
    }

    #{$_root-line}__action {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(calc(100% + 10px), -50%);
    }
  }

  &__content {
    .c-table__outside--right {
      transform: translate(calc(100% + 10px), -50%);
    }
  }
}
