////
/// @group Components
/// Action
////

@use "@glsass/tools/tools.global" as *;

.c-pagination {
  $root-pagination: &;

  display: flex;
  justify-content: center;
  align-items: stretch;

  &--white {
    #{$root-pagination}__item {
      color: cv("white");

      &:hover:not(.is-current) {
        color: cv("black");
        background-color: cv("white");
      }

      &#{$root-pagination}__item--prev,
      &#{$root-pagination}__item--next {
        background-color: cv("gray", 700);
        background-image: url(iv(
          chevron,
          $options: (currentColor: cv("white"))
        ));

        &:hover:not(.is-disabled) {
          background-color: cv("gray", 900);
        }

        &.is-disabled {
          background-color: cv("gray", 200);
        }
      }
    }
  }

  &__item {
    display: block;
    min-width: 40px;
    height: 40px;
    margin-right: 1px;
    color: cv("black");
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.35s ease;
    user-select: none;

    &:last-child {
      margin-right: 0;
    }

    &#{&}--prev,
    &#{&}--next {
      background-color: cv("gray", D00);
      background-image: url(iv(
        chevron,
        $options: (currentColor: cv("gray", 700))
      ));
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px 16px;

      &.is-disabled {
        background-color: cv("gray", E00);
        background-image: url(iv(
          chevron,
          $options: (currentColor: cv("gray", 900))
        ));
      }
    }

    &#{&}--prev {
      transform: rotate(90deg);
    }

    &#{&}--next {
      transform: rotate(-90deg);
    }

    &:hover:not(.is-current):not(.is-disabled) {
      color: cv("black");
      background-color: rgba(cv("gray", D00), 0.7);
    }

    &.is-current {
      color: cv("white");
      background-color: cv("primary");
    }
  }
}
