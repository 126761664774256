////
/// @group Components
/// Tag
////

@use "@glsass/tools/tools.global" as *;

.c-tag {
  display: inline-block;
  padding: 5px 10px;
  color: cv("gray", E00);
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
  background-color: cv("black");

  &--secondary {
    background-color: cv("gray", 700);
  }

  &--success {
    color: cv("white");
    background-color: cv("success");
  }

  &--danger {
    color: cv("white");
    background-color: cv("danger");
  }

  &--invert {
    padding: 4px 9px;
    color: cv("black");
    background-color: cv("white");
    border: 1px solid cv("black");
  }
}

.c-tags {
  display: flex;

  .c-tag {
    flex-shrink: 0;

    + .c-tag {
      margin-left: 1px;
    }
  }
}
