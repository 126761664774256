////
/// @group Components
/// Step
////

@use "@glsass/tools/tools.global" as *;

.c-step {
  $_root-step: &;

  &s {
    display: flex;

    &:first-child {
      margin-left: 0;
    }
  }

  /* stylelint-disable-next-line order/order */
  display: flex;
  align-items: center;
  margin-left: 40px;
  color: cv("gray", 700);
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;
  user-select: none;

  &.is-active {
    color: cv("white");

    #{$_root-step}__indicator {
      color: cv("primary");
      background-color: cv("white");
    }
  }

  &__indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    color: cv("black");
    font-size: 20px;
    font-weight: 900;
    background-color: cv("gray", 300);
    border-radius: 50%;
  }
}
