////
/// @group Components
/// Warning
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.color" as _color;
@use "@glsass/tools/tools.global" as *;

.c-warning {
  $root-warning: &;

  position: relative;
  z-index: 49;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;

  &:hover {
    z-index: 50;

    #{$root-warning}__content {
      display: block;
    }
  }

  &::before {
    content: "";
    display: inline-block;
    width: 19px;
    height: 16px;
    overflow: visible;
    background-color: cv("gray", B00);
    mask-image: url(iv(warning));
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: none;
    padding: 10px;
    color: cv("white");
    font-size: 12px;
    font-weight: bold;
    white-space: pre;
    border-radius: graphics.$ui-border-radius;
    transform: translate(-50%, 100%);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      display: inline-block;
      width: 0;
      height: 0;
      border-width: 0 3px 3px 3px;
      border-style: solid;
      border-color: transparent transparent currentColor transparent;
      transform: translate(-50%, -100%);
    }

    &--large {
      width: max-content;
      max-width: 375px;
      white-space: unset;
    }

    &-r {
      top: 50%;
      right: -8px;
      bottom: auto;
      left: auto;
      transform: translate(100%, -50%);

      &::before {
        top: 50%;
        left: 0;
        border-width: 3px 3px 3px 0;
        border-color: transparent currentColor transparent transparent;
        transform: translate(-100%, -50%);
      }
    }
  }

  &--success {
    &::before,
    #{$root-warning}__content {
      background-color: cv(success);
    }

    #{$root-warning}__content {
      &::before {
        color: cv(success);
      }
    }
  }

  &--warning {
    &::before,
    #{$root-warning}__content {
      background-color: cv(warning);
    }

    #{$root-warning}__content {
      &::before {
        color: cv(warning);
      }
    }
  }

  &--danger {
    &::before,
    #{$root-warning}__content {
      background-color: cv(danger);
    }

    #{$root-warning}__content {
      &::before {
        color: cv(danger);
      }
    }
  }

  &-line {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin-bottom: 1px;
    padding: 10px 20px;
    color: cv("black");
    background-color: _color.tint(cv("danger"), 10);

    &::before {
      content: "";
      flex-shrink: 0;
      width: 19px;
      height: 16px;
      margin-right: 10px;
      background-color: cv("danger");
      mask-image: url(iv(warning));
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
    }

    &--danger {
      background-color: _color.tint(cv("danger"), 10);

      &::before {
        background-color: cv("danger");
      }
    }

    &--warning {
      background-color: _color.tint(cv("warning"), 10);

      &::before {
        background-color: cv("warning");
      }
    }
  }
}
