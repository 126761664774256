////
/// @group Components
/// Report
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.c-report {
  $_root-report: &;

  display: flex;
  margin-bottom: 30px;
  padding: 10px 20px;
  color: cv("black");
  font-weight: 700;
  background-color: cv("white");

  &--danger {
    color: cv("white");
    background-color: cv("danger");

    &::before {
      background-color: cv("white") !important;
    }

    #{$_root-report}__button {
      border-color: cv("white");
    }
  }

  &--kit {
    background-color: cv("kit");
  }

  &::before {
    content: "";
    display: block;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 20px;
    background-color: cv("black");
    mask-image: url(iv(warning));
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
  }

  &__message {
    flex-grow: 1;
  }

  &__button {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-left: 20px;
    padding: 8px 20px;
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
    background-color: rgba(cv("black"), 0.1);
    border: 1px solid cv("black");
    border-radius: graphics.$ui-border-radius;

    &:hover {
      background-color: rgba(cv("black"), 0.2);
    }

    &:focus,
    &:active {
      background-color: rgba(cv("black"), 0.25);
    }
  }
}
