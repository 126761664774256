@use "@glsass/tools/tools.global" as *;

.c-red-warning {
  &__message {
    display: flex;
    margin-bottom: 1px;
    padding: 12px 30px 12px 20px;
    color: cv("white");
    font-size: 14px;
    font-weight: bold;
    background: cv("danger");

    &::before {
      display: block;
      flex-shrink: 0;
      width: 19px;
      height: 16px;
      margin-top: 4px;
      margin-right: 20px;
      background-color: cv("white");
      mask-image: url(iv(warning));
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      content: "";
    }
  }

  &__details {
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    color: cv("black");
    background: cv("white");

    &-status {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: auto;
      padding: 0 40px;

      div {
        display: inline-block;
        padding: 4px 10px;
        color: cv("white");
        font-weight: bolder;
        text-align: center;
        text-transform: uppercase;
        background: cv("danger");
        border-radius: 5px;
      }
    }

    &-position {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: 150px;
      padding-right: 40px;
      padding-left: 0;
    }

    &-reference {
      flex-grow: 2;
      padding-right: 40px;
      padding-left: 0;
    }

    &-format {
      padding-right: 40px;
      padding-left: 0;
    }

    &-image {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: 80px;
      height: 80px;
      padding: 0;
      background: cv("gray", C00);

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
      }
    }

    &-date {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: auto;
      padding-right: 60px;
      padding-left: 0;
    }
  }

  &__confirm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    margin-top: 20px;
    padding: 0 40px;
    color: cv("white");
    background: cv("black");
    border: 2px solid cv("danger");

    p {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
    }

    .c-form__group {
      margin-bottom: 0;
    }
  }
}
