////
/// @group Components
/// Button
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.color" as _color;
@use "@glsass/tools/tools.global" as *;

.c-toasts {
  bottom: 90px !important;

  > .c-toast {
    height: 40px;
    min-height: 40px;
    padding: 0 20px;
    color: cv("white");
    font-size: 14px;
    background-color: cv("gray", 100);
    border-radius: graphics.$ui-border-radius;
    box-shadow: 0 3px 6px rgba(cv("black"), 0.15);

    &::before {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
    }

    &.success {
      color: cv("black");
      background-color: _color.tint(cv("success"), 10);

      &::before {
        content: "";
        background-color: cv("success");
        mask-image: url(iv("success"));
      }
    }

    &.error {
      color: cv("black");
      background-color: _color.tint(cv("danger"), 10);

      &::before {
        content: "";
        background-color: cv("danger");
        mask-image: url(iv("warning"));
      }
    }

    .action.close {
      width: 20px;
      height: 20px;
      margin: 0 -5px 0 5px;
      padding: 0;
      background-image: url(iv("close", $options: (currentColor: cv("black"))));
      background-repeat: no-repeat;
      background-position: center;
      background-size: 10px 10px;
      cursor: pointer;
    }
  }
}
