////
/// @group Modules
/// Alert
////

@use "@glsass/graphics";

@use "@glsass/tools/tools.global" as *;

.m-alert {
  display: grid;
  grid-template-columns: 1rem 1rem 1fr;
  gap: 30px;
  margin-bottom: 20px;

  &--large {
    grid-template-columns: 30px 30px 1fr;
  }
}
