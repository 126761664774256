////
/// @group Utilities
/// Columns custom
////

@use "sass:math";
@use "sass:list";

$percents: 5, 6, 8, 10, 12, 15, 20;

@function fraction($numerator, $denominator: 100, $separator: "\\/") {
  $normalize: normalize($numerator, $denominator);

  $num: list.nth($normalize, 1);
  $denum: list.nth($normalize, 2);

  @return #{$num + $separator + $denum};
}

@function normalize($numerator, $denominator) {
  $gcf: greatest-common-factor($numerator, $denominator);
  $numerator: math.div($numerator, $gcf);
  $denominator: math.div($denominator, $gcf);

  @if $denominator < 0 {
    $numerator: $numerator * -1;
    $denominator: $denominator * -1;
  }

  @return ($numerator, $denominator);
}

@function greatest-common-factor($a, $b) {
  $a: math.abs($a);
  $b: math.abs($b);

  @while ($b > 0) {
    $c: $a % $b;
    $a: $b;
    $b: $c;
  }

  @return $a;
}

@each $percent in $percents {
  $name: fraction($percent);

  .u-#{$name},
  .u-#{$percent}p {
    flex: 0 0 #{math.percentage(math.div($percent, 100))};
    max-width: #{math.percentage(math.div($percent, 100))};
  }
}
