.l-footer {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 40px;
  font-size: 0.75rem;

  &__illu {
    position: absolute;
    right: 0;
    bottom: 40px;
    width: 156px;
    height: 122px;
    // background-image: url("/images/illustration-right-bottom.png");
    background-image: url("../../images/login/illustration-right-bottom.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 156px 122px;
  }
}
