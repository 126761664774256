@use "sass:math";

@use "@glsass/tools/tools.global" as *;

.l-hero {
  position: relative;
  display: flex;
  min-height: 200px;
  padding-top: 40px;
  background-color: cv("gray", 200);
  background-image: linear-gradient(to right, cv("gray", 200), cv("gray", 300));

  &--large {
    min-height: 350px;
  }

  h1 {
    color: cv("white");
    font-size: 30px;
    line-height: math.div(42, 30);
  }

  h2 {
    margin-bottom: -5px;
    color: cv("white");
    font-size: 20px;
    font-weight: 900;
    line-height: math.div(24, 20);
  }

  h3 {
    margin-bottom: 30px;
    color: cv("gray", B00);
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    :first-child {
      font-weight: 300;
    }
  }
}
